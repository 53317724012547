import React, { useEffect, useState } from 'react';
import axiosInstance from '../utility/axios'; // Import the axios instance
import { useNavigate, useParams } from 'react-router-dom';
import NavigationButtons from './shared/NavigationButtons';
import toast, { Toaster } from 'react-hot-toast';

// this code is taken from branch api_integrations'
const Module1A_SME = () => {
  const { module_id, otp } = useParams();
  // console.log('module_id:', module_id);
  // console.log('otp:', otp);
  sessionStorage.setItem('module_id', module_id)
  sessionStorage.setItem('otp', otp)
  
  const navigate = useNavigate(); // Hook to use navigation

  const [smeData, setSmeData] = useState([]);
  const [uniqueHeadings, setUniqueHeadings] = useState([]);
  const [selectedCircles, setSelectedCircles] = useState({})
  const [selectedValue, setSelectedValue] = useState([]);
  const [selections, setSelections] = useState([]); // Track all selections
  const [formattedData, setFormattedData] = useState([])

  useEffect(() => {
    axiosInstance.get(`module/sme?module_id=${module_id}&otp=${otp}`)
      .then(response => {
        const data = response.sort((a, b) => a.id - b.id); // it will sort the data in ascending order of their id
        const headings = [...new Set(data.map(item => item.heading))];
        setUniqueHeadings(headings);

        setSmeData(data);
        console.log('response:', response);
      })
      .catch(error => {
        console.error('error:', error);
      });
  }, [module_id, otp]);

  // this will show the received selected_value for each element
  useEffect(() => {
    if (uniqueHeadings.length > 0 && smeData.length > 0) {
      uniqueHeadings.forEach((heading, headingIndex) => {
        smeData
          .filter(item => item.heading === heading)
          .forEach((item, questionIndex) => {
            setFormattedData(prevState => ([...prevState, {sme_id: item.id, value: item.selected_value}]));

            setSelectedCircles(prevState => ({
              ...prevState,
              [`${heading}-${questionIndex}`]: item.selected_value !== null ? item.selected_value + 2 : null,
            }));
          });
      });
    }
  }, [uniqueHeadings, smeData]); 

  const handlePrevious = () => navigate("/Module_01_Start_SME");

  const handleNext = () => {
    if (Object.keys(selectedCircles).length === 6 && 
      Object.values(selectedCircles).every(value => value !== null && value !== undefined && value !== '')) {
      axiosInstance.post(`/module/save_sme_value?otp=${otp}`, formattedData)
        .then(_ => navigate('/Module_1A_Result_SME'))
        .catch(error => console.log("error:", error));
    } else {
      // If not all selections are made, show an error message
      toast.error("Please select an option for all questions.");
      console.log('error:', 'error');
    }
  };

  const handleCircleClick = (sme_id, heading, questionIndex, idx) => {
    console.log('sme_id:', sme_id);
    // console.log('heading:', heading);
    // console.log('questionIndex:', questionIndex);
    console.log('idx:', idx);
    
    const value = idx - 2;

    // this variable shows the index of clicked circle in smeData
    const questionIndexCopy = smeData.findIndex(item => item.id === sme_id);
    console.log('questionIndexCopy:', questionIndexCopy);

    setSelectedCircles(prevState => ({
      ...prevState,
      [`${heading}-${questionIndex}`]: idx,
    }));

    setFormattedData(prevState => 
      prevState.map(item => 
        item.sme_id === sme_id ? { ...item, value: value } : item
      )
    );

    const stateClone = [...selectedValue];
    stateClone[questionIndexCopy] = {
      "sme_id": sme_id,
      "value": value,
    };
    setSelectedValue(stateClone);

    setSelections(prevSelections => {
      const existingSelectionIndex = prevSelections.findIndex(sel => sel.sme_id === sme_id);
      if (existingSelectionIndex !== -1) {
        const updatedSelections = [...prevSelections];
        updatedSelections[existingSelectionIndex].value = value;
        return updatedSelections;
      } else {
        return [...prevSelections, { sme_id, value }];
      }
    });
  };

  useEffect(() => {
    console.log("selectedValue:", selectedValue);
    // console.log("uniqueHeadings:", uniqueHeadings);
    console.log("selectedCircles:", selectedCircles);
    console.log("smeData:", smeData);
    console.log("formattedData:", formattedData);
    console.log("selections:", selections);
  }, [selectedValue, uniqueHeadings, selectedCircles, smeData, formattedData, selections]);

  return (
    <div className="flex flex-col items-start px-8 py-4 mb-20">
      {/* unique headings are the hadings extracted from smeData */}
      {uniqueHeadings.map((heading, headingIndex) => (
        <div key={headingIndex} className="w-full max-w-screen-md">
          <h2 className="text-light-green text-left font-extrabold pl-4 text-[9px]
            sm:text-[9px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
            2xl:text-[20px]">
            {heading}
          </h2>
          {/* the follwoing method extracts only those elements from smeData whose heading is same as the unique heading(current) */}
          {smeData
            .filter(item => item.heading === heading)
            .map((item, questionIndex) => 
            { 
              // console.log('item:', item);
              
              return(
              <div key={item.sme_id} className="text-dark-green text-left p-4 rounded-lg mb-4 w-full">
                <div className="text-dark-green text-left p-4 rounded-lg mb-4 w-[80vw] text-[11px]
            sm:text-[13px]
            md:text-[16px]
            lg:text-[18px]
            xl:text-[22px]
            2xl:text-[25px]">
                  {item.question}
                </div>
                <div className="relative flex items-center w-full max-w-screen-md">
                  <div className="flex flex-col items-center mt-10 text-red-400 font-base  w-16 text-[10px]
    sm:text-[10px]
    md:text-[16px]
    md:mb-[5px]
    lg:text-[16px]
    xl:text-[18px]
    2xl:text-[19px]">
                    <div>LOSS</div>
                    <div>(-)</div>
                  </div>
                  <div className="relative flex-grow mx-4">
                    <input
                      type="range"
                      min="0"
                      max="100"
                      defaultValue="50"
                      className="w-[65vw] h-1 rounded-lg appearance-none cursor-pointer"
                      style={{
                        background: 'linear-gradient(to right, #F26375 0%, #F26375 45%, #86B2F9 45%, #86B2F9 55%, #39A982 55%, #39A982 100%)'
                      }}
                    />
                    <div className="absolute inset-0 flex items-center justify-between">
                      {item.value.split(',').map((label, idx) => (
                        // this is the div for slider circles
                        <div key={idx} className="relative flex flex-col items-center">
                          <div
                            className={`absolute w-4 h-4 border-2 mt-6 rounded-full text-[10px]
    sm:h-[15px]
    sm:w-[15px]
    sm:mt-[28px]
    md:h-[18px]
    md:w-[18px]
    md:mr-[13px]
    md:my-[27px]
    lg:h-[21px]
    lg:w-[21px]
    lg:ml-[13px]
    xl:h-[22px]
    xl:w-[22px]
    xl:ml-[13px]
    xl:mt-[30px]
    2xl:h-[24px]
    2xl:w-[24px]
    xl:ml-[13px]
    
    ${selectedCircles[`${heading}-${questionIndex}`] === idx ? 'bg-teal-500 border-teal-700' : 'bg-white border-text-green'}`}
                            // here 'item.id' is the unique id of the element from smeData, 'heading' is the current element from
                            // 'uniqueHeadings.map' method, 'questionIndex' is the index of this question in second map method 
                            onClick={() => handleCircleClick(item.id, heading, questionIndex, idx)}
                            style={{ top: '-1px' }}
                          ></div>
                          <div className="text-teal-800 mt-12 text-[8px]
    sm:text-[10px]
    md:text-[12px]
    lg:text-[14px]
    xl:text-[18px]
    2xl:text-[19px]">{label}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col items-center mt-8 text-green-400 font-base w-16 text-[10px]
    sm:text-[10px]
    md:text-[16px]
    lg:text-[16px]
    xl:text-[18px]
    2xl:text-[19px]">
                    <div>GAIN</div>
                    <div>(+)</div>
                  </div>
                </div>
              </div>
            )})}
        </div>
      ))}
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Module1A_SME;