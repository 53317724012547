import React, { useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";

const Module_11 = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate("/Module_11_Start");
  const handleNext = () => navigate("/Module_12_Start");

  return (
    <div className="max-w-full sm:max-w-9xl mx-6 mx-auto p-4 sm:p-8">
      {/* Header */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <h2
          className="text-black font-extrabold mb-2  font-bold sm:mb-0 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]">
          Operationalizing the Strategy
        </h2>
      </div>

      {/* Module Title */}
      <div className="bg-cost p-2">
        <h3
          className="text-body text-[12px] h-[20px]
            sm:text-[18px]
            md:text-[20px]
            md:h-[25px]
            lg:text-[22px]
            lg: mb-[3px]
            lg:h-[28px]
            xl:text-[20px]
            xl:h-[30px]
            2xl:text-[28px]
            2xl:h-[50px]">
          Module 11: Two Question for Value Creation
        </h3>
      </div>
      <div
        className="text-dark-green mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
        Two key questions to ask before implementing any plan or investment:
        <div className="">
          <div className="mt-12 w-full">
            <div
              className="flex justify-between text-center  bg-gradient-to-r from-[rgba(143,147,255,0.95)] to-[rgba(86,88,153,0.95)] text-body font-semibold p-3 text-[7px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]
             ">
    <span className="text-dark-green bg-lemon-green px-2 aspect-square flex items-center">01</span>
    <p className="flex-1 flex items-center justify-center">AM I ON STRATEGY?</p>
            </div>{" "}
            <br />
          </div>
          <div className="">
<div className="w-full">
  <div
    className="flex justify-between text-center  bg-gradient-to-r from-[rgba(143,147,255,0.95)] to-[rgba(86,88,153,0.95)] text-body font-semibold p-3 text-[7px] 
    sm:text-[15px] 
    md:text-[14px] 
    lg:text-[18px] 
    xl:text-[20px] 
    2xl:text-[35px]">
    <span className="text-dark-green bg-lemon-green px-2 aspect-square flex items-center">02</span>
    <p className="flex-1 flex items-center justify-center">WILL THE PLAN CREATE VALUE FOR THE SHAREHOLDERS?</p>
  </div>
  <br />
</div>
          </div>
        </div>
      </div>

      <div className="my-8">
        <ul
          className="list-disc ml-5 list-inside space-y-4 w-[85vw] list-outside text-dark-green mt-8 text-left mb-4 text-[10px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          <li className="text-dark-green">
            Every employee in the company should be clear on the strategy and
            when executing ask the above two questions to bring a laser-sharp
            focus on the key strategic choices.
          </li>
          <li className="text-dark-green">
            The more the whole organization can focus on 'The Agreed Strategy'
            and 'Value Creation,' the more it can create profits and growth.
          </li>
          <li className="text-dark-green">
            Try to quantify every decision wherever possible, especially the key
            ones.
          </li>
        </ul>
      </div>
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_11;
