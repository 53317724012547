import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationButtons from './shared/NavigationButtons';

const Module_11_Start = () => {
    const navigate = useNavigate();
    const onPrevious = () => navigate('/Module_09_e');
    const onNext = () => navigate('/Module_11');
    
    return (
        <div className='flex justify-center items-center h-screen'>
        <div className='w-[50vw] bg-card p-3 font-semibold rounded-3xl text-center text-body text-[13px]  
                text-[10px] 
                sm:text-[15px] 
                md:text-[14px] 
                lg:text-[18px] 
                xl:text-[20px] 
                2xl:text-[30px]'>OPERATIONALIZING THE STRATEGY</div>
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    );
};

export default Module_11_Start;
