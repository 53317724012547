import React from 'react';
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from 'react-router-dom';

const Module_14 = () => {

  const navigate = useNavigate();
  const onPrevious = () => navigate("/Module_14_Start");
  const onNext = () => navigate("/Module_22");

  return (
    <div className='flex flex-col p-4 md:p-12'>
      <p className='text-black font-extrabold mb-2  font-bold sm:mb-0 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]'>Measures</p>
      <div className="bg-light-green p-2 mb-4">
                <h3 className="text-body h-[30px] text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[45px]">Module 14</h3>
            </div>
      <p className='text-dark-green mt-8 text-left mb-4  text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]'>
        Measures must be binary: achieved or not achieved. There should be no ambiguity.The corporate level measures 
        should be a few and they should help provide numerical targets for the strategies to achieve to be termed successful.
      </p>
      <div className="text-light-blue text-dark-green mt-8 text-left mb-4 font-bold text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
        Here is an example of distribution channels in the food distribution channel.
      </div>

      <div className="flex flex-col gap-2 mb-10 md:mb-20">
        <div className="overflow-x-auto">
          <table className=" w-full border border-green-400 border-collapse min-h-[300px] p-3 text-dark-green mt-8 text-left mb-4 
            text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]">
            <thead>
              <tr>
                <th className="border border-green-400 px-2 md:px-4 py-4 w-[25%]">Growth</th>
                <th className="border border-green-400 px-2 md:px-4 py-4">Profitability</th>
                <th className="border border-green-400 px-2 md:px-4 py-4">Productivity</th>
                <th className="border border-green-400 px-2 md:px-4 py-4">Satisfaction</th>
              </tr>
            </thead>
            <tbody className='border border-green-500 p-3 text-dark-green text-dark-green mt-8 text-left mb-4 text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]'>
              <tr>
                <td className="px-4 py-6">Sales vs YA Sales vs Target</td>
                <td className="border border-green-400 px-4 py-6">
                  Margin Expansion vs YA, vs Target<br/> Average Unit price vs YA
                </td>
                <td className="px-4 py-6">ROIC</td>
                <td className="border border-green-400 px-4 py-6">
                  Customer satisfaction<br/>Employee satisfaction
                </td>
              </tr>
              <tr>
                <td className="border border-green-400 px-4 py-6">
                  Customer lifetime value (CLV) <br/>Customer acquisition cost <br/>Customer Churn Rate
                </td>
                <td className="border border-green-400 px-4 py-6">
                  Profit vs YA<br/>Profit vs Target<br/>
                  Organic profit (excl. forex gains, acquisitions etc)<br/>
                  # Of products/services making profit<br/>
                  # Of channels making profit.
                </td>
                <td className="border border-green-400 px-4 py-6 ">Sales/Enrolment</td>
                <td className="px-4 py-6"></td>
              </tr>
              <tr>
                <td className="border border-green-400 px-4 py-6">Cash vs YA</td>
                <td className="border border-green-400 px-4 py-6">Unit cost vs YA, vs Target</td>
                <td className="border border-green-400 px-4 py-6"></td>
                <td className="border border-green-400 px-4 py-6"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-end mt-4">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
}

export default Module_14;
