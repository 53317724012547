
import React, { useEffect } from 'react';
import NavigationButtons from './NavigationButtons';
import QuestionTextarea from './QuestionTextarea';

const ModuleTemplateCustom = ({
  title,
  bgColor,
  goals,
  addOneYearGoal,
  addFiveYearGoal,
  onPrevious,
  onNext,
  handleGoalChange,
  oneYearGoal: oneYearGoalTextarea,
  fiveYearGoal: fiveYearGoalTextarea,
}) => {
  // Split goals into One-Year and Five-Year goal groups for easier rendering
  const oneYearGoals = goals.filter(goal => goal.question === 'One-Year Goal');
  const fiveYearGoals = goals.filter(goal => goal.question === 'Five-Year Goal');

  useEffect(() => {
    fiveYearGoalTextarea &&
      console.log('fiveYearGoalTextarea', Object.entries(fiveYearGoalTextarea));
  }, [fiveYearGoalTextarea]);

  return (
    <div className="p-8 mx-2">
      <h1
        className="font-bold text-light-green mb-1 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]"
      >
        Setting the Direction
      </h1>
      <div className={`${bgColor} bg-dark-green p-1 mb-3 text-white`}>
        <h2
          className="text-body h-[30px] text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[48px]"
        >
          {title}
        </h2>
      </div>

      <div className="mt-8 text-dark-green">
        <p
          className="text-dark-green text-dark-green mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
        >
          <strong> GOALS:</strong> Goal should be measurable and achievable
        </p>

        {/* Rendering One-Year Goals Textareas */}
        <div className="my-4">
          <div>
            <p
              className="text-dark-green font-semibold mt-8 text-left -mb-2 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[19px]
            2xl:text-[25px]"
            >
              One-Year Goals
            </p>
          </div>
          {Object.entries(oneYearGoalTextarea).length > 0 ? (
            Object.entries(oneYearGoalTextarea).map((goal, goalIndex) => (
              <div key={goalIndex} className="mb-4">
                <QuestionTextarea
                  question=""
                  handleGoalChange={handleGoalChange}
                  goalIndex={goalIndex}
                  option={1}
                  initValue={goal[1]}
                />
              </div>
            ))
          ) : (
            // Render a default textarea if no one-year goals exist
            <div className="mb-4">
              <QuestionTextarea
                question=""
                handleGoalChange={handleGoalChange}
                goalIndex={0} // default index
                option={1}
                initValue=""
              />
            </div>
          )}
        </div>

        {/* Rendering Five-Year Goals Textareas */}
        <div className="my-4">
          <div>
            <p
              className="text-dark-green font-semibold mt-8 text-left -mb-2 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[19px]
            2xl:text-[25px]"
            >
              Five-Year Goals
            </p>
          </div>
          {Object.entries(fiveYearGoalTextarea).length > 0 ? (
            Object.entries(fiveYearGoalTextarea).map((goal, goalIndex) => (
              <div key={goalIndex} className="mb-4">
                <QuestionTextarea
                  question=""
                  handleGoalChange={handleGoalChange}
                  goalIndex={goalIndex}
                  option={2}
                  initValue={goal[1]}
                />
              </div>
            ))
          ) : (
            // Render a default textarea if no five-year goals exist
            <div className="mb-4">
              <QuestionTextarea
                question=""
                handleGoalChange={handleGoalChange}
                goalIndex={0} // default index
                option={2}
                initValue=""
              />
            </div>
          )}
        </div>
      </div>

      <div className="mx-2">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
};

export default ModuleTemplateCustom;
