import React, { useEffect, useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";

// EditableTableRow component for Table 2 (second table)
const EditableTableRow = ({ rowData, setTableData, setSingleColumnRows, rowIndex }) => {
  const [row, setRow] = useState([]);

  useEffect(() => {
console.log(rowData)
    if (rowData) {
      setRow(rowData);
    }
  }, [rowData]);

  useEffect(() => {
    setTableData((prevTableData) =>
      prevTableData.map((data) => (data[0] !== row[0] ? data : row))
    );
  }, [row, setTableData]);

  const handleInputChange = (index, value) => {
    const newRow = [...row];
    newRow[index] = value;
    setRow(newRow);

    setSingleColumnRows((prevRows) => {
      const updatedRows = [...prevRows];
      const updatedRow = [...updatedRows[rowIndex]];
      updatedRow[index] = value;
      updatedRows[rowIndex] = updatedRow;
      return updatedRows;
    });
  };

  return (
    <tr>
      {row.map((cell, index) => (
        <td key={index} className={`border border-light-green px-1 py-2 ${index === 0 ? "pl-5" : ""}`}>
          {index === 0 ? (
            cell
          ) : (
            <input
              type="text"
              value={cell || ""}
              onChange={(e) => handleInputChange(index, e.target.value, rowIndex)}
              className="w-full px-1 py-1 border-none bg-transparent focus:outline-none focus:ring-0 text-[7px] sm:text-[6px] md:text-[8px] lg:text-[12px] xl:text-[16px] 2xl:text-[20px]"
            />
          )}
        </td>
      ))}
    </tr>
  );
};

// Table component
const Table = ({ rows, isEditable, setTableData, setSingleColumnRows }) => {
  return (
    <div className="overflow-x-auto mb-8 w-[90%]">
      <table className="w-full table-auto border-collapse border border-light-green text-teal-700 text-[7px] sm:text-[6px] md:text-[8px] lg:text-[12px] xl:text-[16px] 2xl:text-[20px]">
        <tbody>
          {rows.map((rowData, index) =>
            isEditable ? (
              <EditableTableRow
                key={index}
                rowData={rowData}
                setTableData={setTableData}
                setSingleColumnRows={setSingleColumnRows}
                rowIndex={index}
              />
            ) : (
              <TableRow key={index} rowData={rowData} />
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

// TableRow component for non-editable rows
const TableRow = ({ rowData }) => (
  <tr>
    {rowData.map((cell, index) => (
      <td key={index} className="border border-light-green px-4 py-2">
        {cell}
      </td>
    ))}
  </tr>
);

// Main Module_6 component
const Module_6 = () => {
  const savedOtp = sessionStorage.getItem("otp");
  const navigate = useNavigate();
  const [receivedData, setReceivedData] = useState([]);
  const [goToMarketNames, setGoToMarketNames] = useState([]);
  const [goToMarketValues, setGoToMarketValues] = useState([]);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(true);
  const [singleColumnRows, setSingleColumnRows] = useState([]);
  const [data, setData] = useState([]);
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`module/goto-market?otp=${savedOtp}`)
      .then((response) => {
        setGoToMarketNames(response);
        setLoading(false);
      })
      .catch((error) => console.log("error:", error));

    axiosInstance
      .get(`module/goto-market-value?otp=${savedOtp}`)
      .then((response) => {
        setGoToMarketValues(response);
      })
      .catch((error) => console.log("values API error:", error));
  }, []);

  const handlePrevious = () => navigate("/Module_05_Start");
  const handleNext = () => {
    const data = {
      values: postData,
      go_to_market_reference: reference
    };
    axiosInstance
      .post(`/module/goto-market-value?otp=${savedOtp}`, data)
      .then((response) => {
        console.log("Data submitted:", data);
      })
      .catch((error) => {
        console.error("Error occurred while saving go-to-market-values:", error.response?.data || error.message);
      })
      .finally(() => {
        navigate("/Module_07_Start");
      });
  };

  // Data for the tables
  const rows = [
    [
      "List of all the channels in your playing field",
      "Retail",
      "Restaurant",
      "Hotels",
      "Catering",
      "Whole Sale",
      "Govt Rations",
      "Traditional Trade",
      "DTC",
      "Others",
    ],
  ];

  useEffect(() => {
    console.log('goToMarketNames', goToMarketNames)
    setSingleColumnRows(
      goToMarketNames.map((item) => [
        item.go_to_market_name,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ])
    );
  }, []);

  useEffect(() => {
    setData(
      singleColumnRows.map((row, rowIndex) =>
        row.slice(1).map((cell, cellIndex) => ({
          go_to_market_id: goToMarketNames[rowIndex]?.id,
          value: cell,
          cell_index: cellIndex + 1,
        }))
      )
    );
  }, [goToMarketNames, singleColumnRows]);

  useEffect(() => {
    setPostData(flattenArray(data));
  }, [data]);

  function flattenArray(arr) {
    let result = [];
    arr.forEach((element) => {
      if (Array.isArray(element)) {
        result = result.concat(flattenArray(element));
      } else {
        result.push(element);
      }
    });
    return result;
  }

  useEffect(() => {
console.log('goToMarketValues', goToMarketValues)
    if (goToMarketValues?.length > 0) {
      const temp = formatArray(goToMarketValues);
      setSingleColumnRows(temp);
console.log('tmep', temp)
      const referenceApi = goToMarketValues[0]?.reference;
      if (referenceApi) {
        setReference(referenceApi);
      }
    }
  }, [goToMarketValues]);

  function formatArray(data) {
    return Object.values(
      data.reduce((acc, { go_to_market_id, values, cell_index }) => {
      let category;

      switch (go_to_market_id) {
        case 1:
          category = "List of all the channels in your playing field";
          break;
        case 2:
          category = "Your company sales in the channels";
          break;
        case 3:
          category = "Your company profitability in the channels";
          break;
        default:
          category = "Unknown category"; // Optional: handle other cases if needed
      }

        if (category) {
          if (!acc[category]) {
            acc[category] = [category];
          }
          acc[category][cell_index] = values;
        }
        return acc;
      }, {})
    );
  }

  return (
    <div className="max-w-full mx-auto p-4 sm:p-8">
      {/* Header */}
      <div className="font-extrabold mb-4 text-dark-green font-bold  sm:mb-0 inline text-[13px] sm:text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] 2xl:text-[30px]">
        Go-to-Market  
      </div>
      {/* Subheader */}
      <div className="bg-card p-1 mb-3 text-white">
        <h2 className="text-body h-[30px] text-[18px] sm:text-[18px] md:text-[20px] lg:text-[22px] xl:text-[20px] 2xl:text-[28px] 2xl:h-[48px]">
          Module 06
        </h2>
      </div>
      {/* Description */}
      <div className="text-light-blue font-semibold mb-6 mt-8 text-left mb-4 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[17px] xl:text-[20px] 2xl:text-[25px]">
        Here is an example of ways to define your go-to-market strategy
      </div>
      {/* First Table */}
      <Table rows={rows} isEditable={false} />
      {/* Spacer */}
      <div className="my-6"></div>
      {/* Second Table with Editable Columns */}
      <div className="text-card font-semibold mb-6 mt-8 text-left mb-4 text-[11px] sm:text-[13px] md:text-[15px] lg:text-[17px] xl:text-[20px] 2xl:text-[25px]">
        Define your go-to-market strategy:
      </div>
      {loading ? (
        <div>Loading</div>
      ) : (
        <Table rows={singleColumnRows} isEditable={true} setTableData={setData} setSingleColumnRows={setSingleColumnRows} />
      )}
      {/* Text Area */}
      <div className="relative h-32 rounded-2xl my-8">

        <h2
          className="text-dark-green font-semibold mt-8 text-left mb-4 text-[8px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"> Any points that you would like to add for your go to market strategy for reference?</h2>

        <textarea
          className="w-full text-dark-green mb-20 rounded-2xl bg-transparent border border-dark-green text-[8px] sm:text-[10px] sm:h-[95px] md:text-[16px] md:h-[120px] lg:text-[18px] lg:h-[130px] xl:text-[18px] xl:h-[115px] 2xl:text-[19px] 2xl:h-[155px]"
          placeholder={`Enter any additional details regarding your go-to-market strategy.`}
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />
      </div>
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_6;
