import React, { useEffect, useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";

// EditableTableRow component for Table 2 (second table)
const EditableTableRow = ({
  rowData,
  setTableData,
  setSingleColumnRows,
  rowIndex,
}) => {

  const [row, setRow] = useState([]);
  useEffect(() => {
    console.log('rowData', rowData)
    setTableData((prevTableData) =>
      prevTableData.map((data) => (data[0] !== row[0] ? data : row))
    );
  }, [row]);

  useEffect(() => {
    if (rowData) {
      setRow(rowData);
    }
  }, [rowData]);

  const handleInputChange = (index, value) => {
    const newRow = [...row];
    newRow[index] = value;
    setRow(newRow);

    setSingleColumnRows((prevRows) => {
      // Create a copy of the current state
      const updatedRows = [...prevRows];
      // Copy the row we want to update
      const updatedRow = [...updatedRows[rowIndex]];
      // Update the specific cell
      updatedRow[index] = value;
      // Replace the old row with the updated row
      updatedRows[rowIndex] = updatedRow;
      // Return the updated array
      return updatedRows;
    });
  };

  return (
    <tr>
      {row.map((cell, index) => (
        <td
          key={index}
          className={`border border-light-green px-1 py-2 ${
            index === 0 ? "" : ""
          } text-[6px] sm:text-[9px] md:text-[12px] lg:text-[14px] xl:text-[16px]`} // Ensure consistent cell size
        >
          {index === 0 ? (
            cell
          ) : (
            <input
              type="text"
              value={cell}
              onChange={(e) => handleInputChange(index, e.target.value, rowIndex)}
              className="w-full px-1 py-1 border-none bg-transparent focus:outline-none focus:ring-0 text-[8px] sm:text-[10px] md:text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-[20px]"
            />
          )}
        </td>
      ))}
    </tr>
  );
};

// Table component
const Table = ({ rows, isEditable, setTableData, setSingleColumnRows }) => {
  return (
    <div className="overflow-x-auto mb-8 w-[90%]">
      <table
        className="w-full table-fixed border-collapse border border-light-green text-teal-700 text-[7px]
            sm:text-[6px]
            md:text-[7.5px]
            lg:text-[12px]
            xl:text-[15px]
           2xl:text-[20px]"
        style={{ tableLayout: "fixed" }} // Ensure fixed table layout
      >
        <tbody>
          {rows.map((rowData, index) =>
            isEditable ? (
              <EditableTableRow
                key={index}
                rowData={rowData}
                setTableData={setTableData}
                setSingleColumnRows={setSingleColumnRows}
                rowIndex={index}
              />
            ) : (
              <TableRow key={index} rowData={rowData} />
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

// TableRow component for non-editable rows
const TableRow = ({ rowData }) => (
  <tr>
    {rowData.map((cell, index) => (
      <td
          key={index}
          className="border border-light-green px-1 py-2 text-[5px] sm:text-[9px] md:text-[12px] lg:text-[14px] xl:text-[16px]" // Ensure consistent cell size
       >
        {cell}
      </td>
    ))}
  </tr>
);

// Main CustomerDefinitionModule7 component
const Module_5 = () => {
  const savedOtp = sessionStorage.getItem("otp");
  const navigate = useNavigate(); // Hook to use navigation
  const [receivedData, setReceivedData] = useState([]);
  const [consumerCustomerNames, setConsumerCustomerNames] = useState([]);
  const [consumerCustomerValues, setConsumerCustomerValues] = useState([]);
  const [reference, setReference] = useState("");
  const [loading, setLoading] = useState(true);
  const [singleColumnRows, setSingleColumnRows] = useState([]);
  const [data, setData] = useState([]);
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(`module/consumer-customers?otp=${savedOtp}`)
      .then((response) => {
        setConsumerCustomerNames(response);
        setLoading(false);
      })
      .catch((error) => console.log("error:", error));

    axiosInstance
      .get(`module/consumer-customer-values?otp=${savedOtp}`)
      .then((response) => {
        setConsumerCustomerValues(response);
      })
      .catch((error) => console.log("values API error:", error));
  }, []);

  // Event handlers for navigation buttons
  const handlePrevious = () => navigate("/Module_05_Start");
  const handleNext = () => {
    const data = {
      data: postData,
      reference
    }
    console.log('data to be submitted', data)
    axiosInstance
      .post(`/module/consumer-customer-value?otp=${savedOtp}`, data)
      .then((response) => {
        // console.log("response:", response);
      })
      .catch((error) => {
        console.error(
          "Error occurred while saving consumer-customer-value:",
          error.response?.data || error.message
        );
      })
      .finally(() => {
        // Always navigate to the next screen, regardless of success or failure
        navigate("/Module_06_Start");
      });
  };

  // Data for the tables
  const rows = [
    [
      "Demographics",
      "Age",
      "Gender",
      "Income",
      "Marital Status",
      "Ethnicity",
      "Occupation",
      "Family Size",
    ],
    [
      "Geography",
      "Locality",
      "City/Town",
      "Country",
      "Multiple Countries",
      "Region",
      "Regions",
      "Globe",
    ],
    [
      "Market Segment",
      "Demographic",
      "Psychographic",
      "Behavioural",
      "Geographic",
      "",
      "",
      "",
    ],
    [
      "Customer Needs",
      "Price",
      "Convenience",
      "Transparency",
      "Performance",
      "Functionality",
      "Sustainability",
      "Reliability",
    ],
  ];

  useEffect(() => {
console.log(consumerCustomerNames)
    setSingleColumnRows(
      consumerCustomerNames.map((item) => [
        item.consumer_customer_name,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ])
    );
  }, []);
  useEffect(() => {
    setData(
      singleColumnRows.map((row, rowIndex) =>
        row.slice(1).map((cell, cellIndex) => ({
          consumer_customer_id: consumerCustomerNames[rowIndex]?.id,
          value: cell,
          cell_index: cellIndex + 1,
        }))
      )
    );
  }, [consumerCustomerNames, singleColumnRows]);

  useEffect(() => {

    setPostData(flattenArray(data));
  }, [data]);
  useEffect(() => {
  }, [postData]);

  const [tableData, setTableData] = useState(singleColumnRows);

  // this will format tableData in our api desired format
  const formattedTableData = tableData.map((data, index) => {
    let id = 0;

    // Determine the id based on the first element of the row
    if (data[0] === "Demographics") {
      id = 1;
    } else if (data[0] === "Geography") {
      id = 2;
    } else if (data[0] === "Market Segment") {
      id = 3;
    } else if (data[0] === "Customer Needs") {
      id = 4;
    }

    // Return formatted data if index is greater than 0
    return data
      .map((item, itemIndex) => {
        // Skip the first item of each row (assuming it's a header or label)
        if (itemIndex > 0) {
          return { consumer_customer_id: id, value: item };
        }
        return null; // or just skip adding this item
      })
      .filter((item) => item !== null); // filter out any null items
  });

  function formatArray(data) {
    return Object.values(
      data.reduce((acc, { consumer_customer_id, value, cell_index }) => {
        // Determine the key based on the consumer_customer_id
        const category =
          consumer_customer_id === 1
            ? "Demographics"
            : consumer_customer_id === 2
            ? "Geography"
            : consumer_customer_id === 3
            ? "Market Segment"
            : consumer_customer_id === 4
            ? "Customer Needs"
            : "";

        console.log(category)

        // If category is valid, proceed
        if (category) {
          // Initialize the array for each unique category if not already present
          if (!acc[category]) {
            acc[category] = [category];
          }
          acc[category][cell_index] = value;
          console.log('value: ', value, '', acc[category])
        }

        return acc;
      }, {})
    );
  }

  useEffect(() => {
    if (consumerCustomerValues.length > 0) {
      const temp = formatArray(consumerCustomerValues);
      console.log('calulatedRow', temp)
      setSingleColumnRows(temp);
      const referenceApi = consumerCustomerValues[0]?.reference;
      if(referenceApi){
      setReference(referenceApi)
      }
    }
  }, [consumerCustomerValues]);

  function flattenArray(arr) {
    let result = [];

    arr.forEach((element) => {
      if (Array.isArray(element)) {
        result = result.concat(flattenArray(element));
      } else {
        result.push(element);
      }
    });

    return result;
  }

  useEffect(() => {
  }, [tableData, formattedTableData, receivedData]);

  return (
    <div className="max-w-full mx-auto p-4 sm:p-8">
      {/* Header */}
      <div
        className="font-extrabold mb-4 text-dark-green font-bold  sm:mb-0 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]">
        Consumer / Customer
      </div>
      {/* Subheader */}
      <div className="bg-card p-1 mb-3 text-white">
        <h2
          className="text-body h-[30px] text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[48px]">
          Module 05
        </h2>
      </div>
      {/* Description */}
      <div
        className="text-light-blue font-semibold mb-6 mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
        Here is an example of ways to define your customer
      </div>
      {/* First Table */} <Table rows={rows} isEditable={false} />
      {/* Spacer */}
      <div className="my-6"></div> {/* Adjusted space between the tables */}
      {/* Second Description */}
      <div
        className="text-card font-semibold mb-6 mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
        How do you define your customer?
      </div>
      {/* Second Table with Editable Columns */}
      {loading ? (
        <div>Loading</div>
      ) : (
        <Table
          rows={singleColumnRows}
          isEditable={true}
          setTableData={setTableData}
          setSingleColumnRows={setSingleColumnRows}
        />
      )}
      <div>
        <h2
          className="text-dark-green font-semibold mt-8 text-left mb-4 text-[8px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          What gives you the reason to believe that you can serve your customer
          better
        </h2>

        {/* Text Section */}
        <div className="relative  h-32 rounded-2xl my-8">
<textarea
  className="w-full text-dark-green rounded-2xl bg-transparent border border-dark-green
    text-[10px] sm:text-[12px] sm:h-[95px] md:text-[14px] md:h-[120px] lg:text-[16px] lg:h-[130px] xl:text-[18px] xl:h-[115px] 2xl:text-[19px] 2xl:h-[155px]"
  placeholder={`The business starts with the customer and the mission to serve them in the best possible way. In some cases (B2B), the customer is also the consumer of the products and services.\nWe use demographics such as age, gender, income, marital status, ethnicity, occupation, and family size to define and understand the consumer/customer base.`}
  value={reference} // Bind the state to the textarea value
  onChange={(e) => setReference(e.target.value)} // Update the state on change
/>
          <div className="absolute top-[-4.0rem] right-[130px] mt-2 group">
            <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
              <span
                className="absolute font-semibold right text-[9px] top-[.70rem] right-[-120px]
            sm:text-[13px]
            sm:-right-[125px]
            sm:-top-[-.20rem]
            md:-right-[135px]
            md:text-[15px]
            md:-top-[-.40rem]
            lg:text-[16px] 
            lg:-right-[125px]
            lg:-top-[-.20rem]
            xl:text-[18px]
            2xl:text-[20px]">
                Suggestions
              </span>
            </div>
            <div
              className="hidden group-hover:block text-dark-green absolute top-0 right-0 w-[300px] bg-white border p-2 rounded-lg shadow-lg  text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[13px]
            2xl:text-[15px]">
              <p>
                The business starts with the consumer at the centre and the
                mission to serve the consumer and the customers in the best
                possible way.
              </p>
              <hr className="my-2 border-t-2 border-lemon-green" />
              <p>
                The consumers and customers are delighted when their needs are
                met by the company in a way that no other company meets.
              </p>
            </div>
          </div>
        </div>
      </div>
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_5;
