import React, { useEffect, useState } from 'react';
import Timeline2 from './shared/Timeline2';
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from 'react-router-dom';
import { StrategySection } from './shared/StrategyComponents';
import axiosInstance from "../utility/axios";
import Spinner from "./shared/Spinner";

const Module_09_d = () => {
  const [goals, setGoals] = useState(null);
  const [goalStrategy, setGoalStrategy] = useState({});
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(true);

  const navigate = useNavigate();
  const onPrevious = () => navigate("/Module_09_c");
  const onNext = async () => {
    console.log(goalStrategy);

    // Prepare data for submission
    const dataToSubmit = {
      parent: key, // Replace with the correct parent ID if necessary
      one_year_strategy: goalStrategy.one_year_goals || [],
      five_year_strategy: goalStrategy.five_year_goals || []
    };

    try {
      const response = await axiosInstance.post(`module/choices-of-winning-strategy-store?otp=${sessionStorage.getItem("otp")}`, dataToSubmit);
      console.log("Submission response:", response);
      // Navigate to the next module or handle success
      navigate("/Module_09_e");
    } catch (error) {
      console.error("Error submitting strategies:", error);
    }
  };

  const savedOtp = sessionStorage.getItem("otp");
  const completedSteps = ["PLAYING FIELD", "WINNING STRATEGY"];

  useEffect(() => {
    if (savedOtp) {
      axiosInstance
        .get(`module/setting-the-direction-goals?otp=${savedOtp}&name=purpose`)
        .then((response) => {
          console.log("Goals response:", response);
          const { one_year_goals, five_year_goals, key: parentKey } = response;
          const newGoals = {};
          if(parentKey) setKey(parentKey);
          if (one_year_goals) {
            newGoals.one_year_goals = one_year_goals;
          }
          if (five_year_goals) {
            newGoals.five_year_goals = five_year_goals;
          }
          setGoals(newGoals);
        })
        .catch((error) => console.log("Error fetching goals:", error))
        .finally(() => setLoading(false));
    }
  }, [savedOtp]);

  useEffect(() => {
    if (goals) {
      console.log("Goals received:", goals);
      axiosInstance
        .get(`module/choices-of-winning-strategy-store?otp=${savedOtp}`)
        .then((response) => {
          console.log("Strategies response:", response);
          const { one_year_strategy, five_year_strategy } = response;
          const initialGoalStrategy = {};
          if (one_year_strategy) {
            initialGoalStrategy.one_year_goals = one_year_strategy;
          }
          if (five_year_strategy) {
            initialGoalStrategy.five_year_goals = five_year_strategy;
          }
          setGoalStrategy(initialGoalStrategy);
        })
        .catch((error) => console.log("Error fetching strategies:", error));
    }
  }, [goals, savedOtp]);

  useEffect(() => {
    console.log('Current goalStrategy:', goalStrategy);
  }, [goalStrategy]);

  return (
    <div className='flex flex-col p-8'>
      <p className='font-bold inline 
            text-[16px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[26px]
            2xl:text-[30px]'>Choices of Winning Strategy</p>
      <div className='mb-8'>
        <p className='bg-light-purple p-2 text-body 
            text-[16px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[24px]
            2xl:text-[28px]
            2xl:h-[50px]'>Module 10</p>
      </div>

      <Timeline2 completedSteps={completedSteps} />

      <div className="flex flex-col gap-2 mb-14">
        <p className='bg-card p-2 text-body 
            text-[16px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[24px]
            2xl:text-[25px]'>STEP 02: Winning Strategy</p>  
      </div>

      {loading ? (
        <Spinner />
      ) : (
        goals ? (
          <StrategySection 
            title="Example: Enterprise Strategy 1"
            description="How would you grow enterprise sales by winning with the consumers/customers?"
            goals={goals}
            goalStrategy={goalStrategy}
            setGoalStrategy={setGoalStrategy}
          />
        ) : (
          <div>Error while fetching goals</div>
        )
      )}

      <div className="flex justify-end mt-4">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
};

export default Module_09_d;