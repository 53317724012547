import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios"; // Import the axios instance
import ModuleTemplate from "./shared/ModuleTemplate";
import toast, { Toaster } from "react-hot-toast";

const Module_07 = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [data, setData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [updateItemId, setUpdateItemId] = useState([]);
  const [postItemId, setPostItemId] = useState([]);
  const [heading, setHeading] = useState("");

  useEffect(() => {
    // it gives the objects with null answers
    axiosInstance
      .get(`module/competitive_landscape?otp=${savedOtp}&section_id=1`)
      .then((response) => {
        console.log("response:", response);
        const sortedResponse = response.sort((a, b) => a.id - b.id);
        setPostData(
          sortedResponse.map((item) => ({
            question_id: item.id,
            section_id: item.section_id,
            competitive_landscape_value: "",
          }))
        );
        setData(response.sort((a, b) => a.id - b.id));
        setHeading(response[0].heading);
      })
      .catch((error) => console.log("error:", error));

    // it gives answers also
    axiosInstance
      .get(`module/competitive_landscape_value?otp=${savedOtp}&section_id=1`)
      .then((response) => {
        console.log("values api response:", response);

        if (response.length > 0) {
          // setData(response.sort((a, b) => a.id - b.id));
          const sortedResponse = response.sort((a, b) => a.id - b.id);

          setAnswers(response.sort((a, b) => a.id - b.id));
          // setData(prevState=> prevState.map((item)=>))
          setData((prevState) =>
            prevState.map((item) => {
              // Find the matching object in the other array by id
              const matchingItem = sortedResponse.find(
                (otherItem) => otherItem.id === item.id
              );

              // If a match is found, replace the attribute (e.g., 'someAttribute')
              if (matchingItem) {
                return {
                  ...item, // Spread the original item to retain other attributes
                  competitive_landscape_value:
                    matchingItem.competitive_landscape_value, // Replace the specific attribute
                };
              }

              // If no match is found, return the item as is
              return item;
            })
          );
        }
      })
      .catch((error) => console.log("error:", error));
  }, []);

  useEffect(() => {
    console.log("data:", data);
    console.log("updateData:", updateData);
    //   console.log('updateItemId:', updateItemId);
    //   console.log('postItemId:', postItemId);
    console.log("postData:", postData);
  }, [data, postData, updateData, updateItemId, postItemId]);

  useEffect(() => {
    // Filter data based on updateItemId array and update updateData state
    const updatedItems = data
      .filter((item) => updateItemId.includes(item.id)) // Check if item.id is in updateItemId
      .map((item) => ({
        section_id: 1,
        question_id: item.id,
        competitive_landscape_value: item.competitive_landscape_value,
      }));

    setUpdateData(updatedItems);
  }, [updateItemId, data]);

  const handleChange = (index, value) => {
    console.log("index:", index);

    const newData = [...data];
    newData[index] = {
      ...newData[index],
      competitive_landscape_value: value,
    };
    setData(newData);

    const newPostData = [...postData];
    newPostData[index] = {
      ...newPostData[index],
      competitive_landscape_value: value,
    };

    console.log('newData', newData)
    console.log('newPostData', newPostData)

    setPostData(newPostData);
  };

  const handleSubmit = async () => {
    const temp = data.map(item => ({...item, question_id: item.id}))
    axiosInstance
      .post(`module/competitive_landscape_value?otp=${savedOtp}`, temp)
      .then((response) => {
        console.log("POST response:", response);
      })
      .catch((error) => console.log("error:", error));
    navigate("/Module_07b");
  };

  return (
    <div>
      <ModuleTemplate
        title={`01: ${heading}`}
        bgColor="bg-cost"
        questions={data.map((item) => item.question)}
        answers={data.map((item) => item.competitive_landscape_value)}
        onPrevious={() => navigate("/Module_07_Start")}
        onNext={handleSubmit}
        onChange={handleChange}
      />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Module_07;
