import React, { useState } from "react";

const Strategy2 = ({ strategies, onInputChange }) => {
  const handleInputChange = (index, value) => {
    onInputChange(index, value);
  };

  return (
    <>
      {strategies.map((strategy, index) => {
        const { number, question, value } = strategy;
        return (
          <div className="flex flex-col gap-2" key={index}>
            <p className="text-dark-green mt-4 font-bold">{number}</p>
            <p className="text-dark-green">{question}</p>
            <input
              className="bg-transparent border border-light-green text-dark-green rounded-2xl w-full p-4 text-[10px]
          sm:text-[12px]
          md:text-[14px]
          lg:text-[16px]
          xl:text-[18px]
          2xl:text-[19px]"
              value={value}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
          </div>
        );
      })}
    </>
  );
};

export default Strategy2;
