import React from 'react';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';


const Module_8 = () => {
    const navigate = useNavigate();
   
    return (
        <div className="p-8 bg-body mx-3 min-h-screen">
            <h1 className="text-lg font-bold mb-2 text-light-green">Setting the Direction</h1>
            <div className="bg-dark-green p-1 mb-3 text-white">
                <h2 className="text-lg font-base p-1">Module 08</h2>
            </div>
            <div>
                <div className="mb-8">
                    <p className="text-dark-green mt-3">
                        <strong>PURPOSE:</strong> A Purpose statement is a single statement that defines the reason your company exists—beyond simply making a profit.
                        It also illustrates how your product or service positively impacts the people you serve. Once your purpose is established,
                        you’ll need a series of goals to drive that purpose. Purpose is the <strong>"Why"</strong> your business exists. Qualities of a good purpose statement:
                    </p>
                    <ul className="list-decimal list-inside mb-4 text-dark-green ml-2">
                        <li>It differentiates the company from competition.</li>
                        <li>A strong purpose statement sets a path for how your company will move forward, which will help you see and set clear goals.</li>
                    </ul>
                    <p className="text-dark-green font-semibold mb-2">Draft your personal statement</p>
                    <div className="relative">
                        <textarea
                            className="w-full h-24 p-4 text-dark-green border border-green-500 rounded-lg bg-transparent"
                            placeholder="Tips: The purpose should include the why it exists and how it improves the lives of its customers."
                        />
                        <div className="absolute top-[-2.40rem] right-[100px] mt-2 group">
                            <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
                                <span className="absolute font-semibold right">Suggestions</span>
                            </div>
                            <div className="hidden group-hover:block absolute top-0 right-0 w-[300px] bg-white border p-2 rounded-lg shadow-lg text-xs sm:text-sm">
                                <h1 className='text-card font-semibold'>AT&T</h1>
                                <p>Inspire human progress through the power of communication and entertainment.</p>
                                <hr className="my-2 border-t-2 border-lemon-green" />
                                <h1 className='text-card font-semibold'>Coca-Cola</h1>
                                <p>Refresh the world. Make a difference.</p>
                                <hr className="my-2 border-t-2 border-lemon-green" />
                                <h1 className='text-card font-semibold'>Pfizer</h1>
                                <p>Working together for a healthier world.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <p className="text-dark-green mb-4">
                        <strong>VISION:</strong> Vision setting is all about the power of imagination. Imagine what your company would be in 5-10 years if it is successful.
                        A vision statement provides a brief description of a company’s long-term goals. It is typically ambitious and communicates how
                        the company plans to make a difference in the world.
                    </p>
                    <p className="text-dark-green font-semibold mb-2">Draft your vision statement</p>
                    <div className="relative">
                        <textarea
                            className="w-full h-24 p-4 text-dark-green border border-green-500 rounded-lg bg-transparent"
                            placeholder="Tips: The vision statement should include what you want your company to become and how it will contribute to the world."
                        />
                        <div className="absolute top-[-2.40rem] right-[100px] mt-2 group">
                            <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
                                <span className="absolute font-semibold right">Suggestions</span>
                            </div>
                            <div className="hidden group-hover:block absolute top-0 right-0 w-[300px] bg-white border p-2 rounded-lg shadow-lg text-xs sm:text-sm">
                                <h1 className='text-card font-semibold'>Tesla</h1>
                                <p>To create the most compelling car company of the 21st century by driving the world’s transition to electric vehicles.</p>
                                <hr className="my-2 border-t-2 border-lemon-green" />
                                <h1 className='text-card font-semibold'>Amazon</h1>
                                <p>To be Earth’s most customer-centric company; to build a place where people can come to find and discover anything they might want to buy online.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Objective Table Section */}
            <div className="my-8">
                <table className="table-fixed w-4/5 border-collapse border border-green-500 text-dark-green">
                    <thead>
                        <tr>
                            <th className="border border-green-500 py-2"></th>
                            <th className="border border-green-500 text-sm px-4">Market Share</th>
                            <th className="border border-green-500 text-sm p-2">Profit</th>
                            <th className="border border-green-500 text-sm p-2">Productivity</th>
                            <th className="border border-green-500 text-sm p-2">Innovation</th>
                            <th className="border border-green-500 text-sm p-2">Organization/Society</th>
                            <th className="border border-green-500 text-sm p-2">Others</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-green-500 p-1">
                                What are your company objectives to meet the vision? One year and 3 years.
                            </td>
                            <td className="border border-green-500"></td>
                            <td className="border border-green-500"></td>
                            <td className="border border-green-500"></td>
                            <td className="border border-green-500"></td>
                            <td className="border border-green-500"></td>
                            <td className="border border-green-500"></td>
                        </tr>
                        <tr>
                            <td className="border border-green-500 p-1">
                                What competencies will be needed to deliver the objectives
                            </td>
                            <td className="border border-green-500 p-4"></td>
                            <td className="border border-green-500 p-4"></td>
                            <td className="border border-green-500 p-4"></td>
                            <td className="border border-green-500 p-4"></td>
                            <td className="border border-green-500 p-4"></td>
                            <td className="border border-green-500 p-4"></td>
                        </tr>
                    </tbody>
                </table>

                <h2 className="text-card text-lg font-semibold mt-8 mb-4">
                    Use the example above to fill in your objectives
                </h2>
                <table className="w-3/4 border-collapse border border-green-500 text-dark-green" style={{ tableLayout: "fixed" }}>
    <tbody>
        <tr>
            <td className="border border-green-500 w-1/4">
                What are your company objectives to meet the vision? One year and 3 years.
            </td>
            <td className="border border-green-500 px-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
        </tr>
        <tr>
            <td className="border border-green-500 p-1 w-1/4">
                What competencies will be needed to deliver the objectives
            </td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden" }}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
            <td className="border border-green-500 p-1" contentEditable="true" style={{ whiteSpace: "nowrap", overflow: "hidden",}}></td>
        </tr>
    </tbody>
</table>


            </div>

            <NavigationButtons   />
        </div>
    );
};

export default Module_8;
