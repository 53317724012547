import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_05_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_4');
  const handleNext = () => navigate('/Module_5');

  return (
    <div className="flex items-center justify-center min-h-screen">
    <div className={`w-full max-w-[600px] 
        sm:max-w-[1100px] 
        md:max-w-[600px] 
        lg:max-w-[800px] 
        xl:max-w-[1000px] 
        2xl:max-w-[1483px] 
        p-4 sm:p-6 md:p-7 lg:p-9 xl:p-8 2xl:p-16`}>
      <TransitionModule
        title={
          <div className="tracking-[8px] text-[12px] 
              sm:text-[16px] 
              md:text-[17px] 
              lg:text-[19px] 
              xl:text-[22px] 
              2xl:text-[35px]">
            CONSUMER/CUSTOMER
          </div>
        }
        description={
          <div className="block mt-4 text-center text-[11px] leading-relaxed
              text-[10px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]">
The essence of any successful business lies in its mission to meet and exceed consumer needs. A business exists to serve its customers, making them the very heart of its purpose and strategy. Every strategic decision should begin by clearly defining who the customer is and understanding their needs, desires, and challenges. Whether in a B2C or B2B context, the customer remains the central focus, as they are often both the purchaser and the end-user of your products and services. By keeping the customer at the core, businesses can craft strategies that not only resonate with their target audience but also drive long-term success and loyalty.          </div>
        }
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
  </div>
  );
};

export default Module_05_Start;
