import React from 'react';

const InputWithLabel = ({ label, value, onChange }) => {
  return (
    <div className="flex flex-col gap-2 w-full my-2">
      <label className="block text-lg font-semibold text-dark-green mb-1 text-[15px]
            sm:text-[17px]
            md:text-[18px]
            lg:text-[19px]
            xl:text-[20px]
            2xl:text-[25px]">{label}</label>
      <textarea
        type="text"
        onChange={onChange}
        className=" px-3 py-2 text-dark-green border border-dark-green bg-transparent rounded-xl focus:outline-none focus:ring-1 focus:ring-dark-green 
            max-w-[600px]
            sm:max-w-[500px]
            md:max-w-[600px]
            lg:max-w-[800px]
            xl:max-w-[1100px]
            2xl:max-w-[1375px]
            p-2 
            sm:p-4 
            md:p-3
            lg:p-4
            xl:p-3
            2xl:p-8
            text-[12px]
            sm:text-[12px] 
            md:text-[14px] 
            lg:text-[16px] 
            xl:text-[18px]  
            2xl:text-[25px]"
        value={value}
      />
    </div>
  );
};

export default InputWithLabel;
