import React, { useEffect, useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios"; // Import the axios instance
import Spinner from "./shared/Spinner";

const Module_22 = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [formData, setFormData] = useState([]); // Initialize an empty array
  const [loading, setLoading] = useState(true); // Loading state for initialization
  const [parent, setParent] = useState(null); // State to hold the parent value

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get(
          `module/measure?otp=${savedOtp}`
        );
        console.log("Initialization response:", response);

        // Ensure you have data in the correct format
        console.log("Goal Records:", response.goals);
        console.log("Measure Records:", response.measure);
        console.log("Result Records:", response.results);

        setParent(response.parent);

        const combinedData = [
          ...Object.entries(response.goals).map(([key, goals], index) => ({
            goal: response.goals[index] || "",
            measure: response.measure[index] || "",
            result: response.results[index] || "",
          })),
        ];

        console.log("Combined Data:", combinedData); // Log combinedData

        setFormData(combinedData);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [savedOtp]);

  // Handle form input changes
  const handleInputChange = (e, index, field) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = e.target.value;
    setFormData(updatedFormData);
  };

  // Handle form submission
  const onNext = async () => {
    // Convert measures and results into objects with indices as keys
    const measureObject = {};
    const resultObject = {};

    formData.forEach((item, index) => {
      measureObject[index] = item.measure; // Use index as key
      resultObject[index] = item.result; // Use index as key
    });

    const data = {
      module_id: 14,
      parent: parent, // Use the fetched parent value
      measure: measureObject,
      results: resultObject,
    };

    try {
      await axiosInstance.post(`/module/measure?otp=${savedOtp}`, data);
      console.log("Data posted successfully:", data);
      navigate("/Module_15_Start"); // Navigate to the next module
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const onPrevious = () => navigate("/Module_14");

  return (
    <div className="flex flex-col p-6 sm:p-12">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          <p
            className="text-black font-extrabold mb-2 font-bold sm:mb-0 inline text-[13px]
              sm:text-[17px]
              md:text-[18px]
              lg:text-[21px]
              xl:text-[22px]
              2xl:text-[30px]">
            Measures
          </p>
          <div className="bg-light-green p-2 mb-4">
            <h3
              className="text-body h-[30px] text-[18px] 
                sm:text-[18px]
                md:text-[20px]
                lg:text-[22px]
                lg: mb-[3px]
                xl:text-[20px]
                2xl:text-[28px]
                2xl:h-[45px]">
              Module 14
            </h3>
          </div>

          <p
            className="text-dark-green mt-8 text-left mb-4 text-[11px]
              sm:text-[13px]
              md:text-[15px]
              lg:text-[17px]
              xl:text-[20px]
              2xl:text-[25px]">
            Measures must be <strong>binary: achieved or not achieved.</strong>
            There should be no ambiguity. The corporate level measures should be
            few and they should help provide numerical targets for the
            strategies to achieve to be termed successful.
          </p>

          <div
            className="text-dark-green mt-6 text-left mb-4 font-bold text-[11px]
              sm:text-[13px]
              md:text-[15px]
              lg:text-[17px]
              xl:text-[20px]
              2xl:text-[25px]">
            Enterprise Selected Measures
          </div>

          <div className="flex flex-col gap-2 mb-20">
            <Table formData={formData} handleInputChange={handleInputChange} />
          </div>

          <div className="flex justify-end mt-4">
            <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
          </div>
        </>
      )}
    </div>
  );
};

const Table = ({ formData, handleInputChange }) => {
  // Adjust the height dynamically based on the content
  const adjustTextareaHeight = (e) => {
    e.target.style.height = "auto"; // Reset height to auto to allow shrinking
    e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scrollHeight (content size)
  };

  return (
    <div className="overflow-x-auto">
      <table
        className="w-full min-w-[250px] border border-green-500 p-3 text-dark-green mt-8 text-left mb-4 text-[7px]
          sm:text-[9px]
          md:text-[11px]
          lg:text-[13px]
          xl:text-[20px]
          2xl:text-[20px]">
        <thead>
          <tr className="text-left">
            <th className="sm:px-4 py-2 w-[40%]">Goals</th>
            <th className="border border-green-400 px-2 sm:px-4 py-2 w-[30%]">Measures</th>
            <th className="border border-green-400 px-2 sm:px-4 py-2 w-[30%]">Results</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((row, index) => (
            <tr key={index}>
              <td className="border border-green-400 px-4 py-4 align-top"> {/* Add padding here */}
                <textarea
                  value={row.goal || ""}
                  disabled
                  className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
                    sm:text-[12px]
                    md:text-[14px]
                    lg:text-[16px]
                    xl:text-[18px]
                    2xl:text-[19px]"
                  onInput={adjustTextareaHeight}
                  style={{
                    overflow: "hidden", // Hide scrollbars
                    height: "auto", // Allow auto-height to start
                    resize: "none", // Prevent manual resizing by user
                  }}
                  rows={Math.max((row.goal || "").split('\n').filter(line => line.trim() !== '').length, 1)} // Automatically set rows based on content
                />
              </td>
              <td className="border border-green-400 px-4 py-4 align-top"> {/* Add padding from top and left */}
                <textarea
                  value={row.measure || ""}
                  onChange={(e) => handleInputChange(e, index, "measure")}
                  className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
                    sm:text-[12px]
                    md:text-[14px]
                    lg:text-[16px]
                    xl:text-[18px]
                    2xl:text-[19px]"
                  onInput={adjustTextareaHeight}
                  style={{
                    overflow: "hidden",
                    height: "auto", // Allow auto-height to start
                    resize: "none",
                  }}
                  rows={Math.max(row.measure.split('\n').length, 1)}
                />
              </td>
              <td className="border border-green-400 px-4 py-4 align-top"> {/* Add padding from top and left */}
                <textarea
                  value={row.result || ""}
                  onChange={(e) => handleInputChange(e, index, "result")}
                  className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
                    sm:text-[12px]
                    md:text-[14px]
                    lg:text-[16px]
                    xl:text-[18px]
                    2xl:text-[19px]"
                  onInput={adjustTextareaHeight}
                  style={{
                    overflow: "hidden",
                    height: "auto", // Allow auto-height to start
                    resize: "none",
                  }}
                  rows={Math.max(row.result.split('\n').length, 1)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export default Module_22;