import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_01_StartUps = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_8b');
  const handleNext = () => navigate('/Module_09_b');

  // Description content
  const descriptionContent = [
    '1. Selecting the right playfield ',
    '2. Developing the strategies to win in the playfield',
    '3. What Not To Do' 
  ];

  return (
    <TransitionModule
      title={
      <div className="racking-[8px] text-[13px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]
      ">
        DEVELOPING THE WINNING STRATEGY
        </div>}
      description={
        <div>
           <p className='my-4 
            text-[12px]
            sm:text-[18px] 
            md:text-[15px] 
            lg:text-[20px] 
            xl:text-[18px]  
            2xl:text-[30px]'>
              In the section we will focus on:</p>
          {descriptionContent.map((item, index) => (
            <p className='mb-2  
            text-[12px]
            sm:text-[18px] 
            md:text-[15px] 
            lg:text-[20px] 
            xl:text-[18px]  
            2xl:text-[30px]'
             key={index}>{item}</p>
          ))}
        </div>
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_01_StartUps;