import React, { useState, useEffect } from 'react';

const Timeline2 = ({ completedSteps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      number: '01',
      content: "PLAYING FIELD",
      isComplete: false,
    },
    {
      number: '02',
      content: "WINNING STRATEGY",
      isComplete: false,
    },
    {
      number: '03',
      content: "WHAT NOT TO DO",
      isComplete: false,
    }
  ]);

  const handleStepClick = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  // Update the isComplete status based on the completedSteps prop
  useEffect(() => {
    const updatedSteps = steps.map(step => ({
      ...step,
      isComplete: completedSteps.includes(step.content)
    }));
    setSteps(updatedSteps);
    console.log(completedSteps)
  }, [completedSteps]);

  return (
    <div className="flex flex-col w-1/2 ">
      <div className="flex items-center mb-16 w-full  ">
        {steps.map((step, index) => (
          <React.Fragment key={step.number}>
            <div
              className={`relative flex items-center justify-center rounded-full border cursor-pointer  
                h-[30px]
                w-[30px]
                sm:h-[30px]
                sm:w-[30px]
                md:h-[30px]
                md:w-[30px]
                lg:h-[40px]
                lg:w-[40px]
                xl:h-[50px]
                xl:w-[50px]                
                ${
                step.isComplete ? 'border-light-green bg-light-green text-white' : 
                'font-bold border-4 bg-transparent border-light-purple text-light-blue text-[15px] sm:text-[17px] md:text-[19px] lg:text-[23px] xl:text-[26px] 2xl:text-[29px]'
              }`}
              onClick={() => handleStepClick(step.number)}
            >
              {step.isComplete ? <img src='/icons/tick.svg' alt="Completed" 
              className='w-5 h-5'/> : step.number}
              <span className={`font-normal pl-8 text-black text-center absolute top-16 text-nowrap text-[5px] mr-[72px]
            sm:text-[10px]
            sm:mr-[56px]
            sm:top-12
            md:text-[11px]
            md:mr-[30px]
            md:top-14
            lg:text-[13px]
            lg:mr-[18px]
            xl:text-[16px]
            xl:mr-[18px]
            2xl:text-[18px] 
             
            ${index==0 && "ml-12"}`}>{step.content}</span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`h-1 flex-1 ${
                  index === 0 ? 'bg-light-green' : 'bg-light-purple'
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Timeline2;
