import React from 'react';

const ElaborateInput = ({ onChange, value }) => {
  const handleInputChange = (e) => {
    onChange(e.target.value); // Pass the input value to the parent component

  };

  

  return (
    <div className="w-full mt-4 sm:mt-6">
      <textarea
        placeholder="Elaborate"
        className="w-full h-[75px] p-2 border border-dark-green bg-transparent rounded-lg focus:outline-none focus:ring-2 focus:ring-dark-green text-[12px]
            sm:text-[13px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
            2xl:text-[20px]
            2xl:h-[101px]"
        onChange={handleInputChange}
        value={value}
      />
    </div>
  );
};

export default ElaborateInput;
