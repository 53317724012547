import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_02_Start = () => {
  const navigate = useNavigate();
  const module = localStorage.getItem('module')

  const handlePrevious = () => navigate(module === 'sme' ? '/Module_1A_Result_SME' : '/Module_1B_Result_Startups');
  const handleNext = () => navigate('/Module_02');

  return (
    <TransitionModule
      title={<div className="tracking-[8px] text-[13px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]"
      >
        CURRENT STRATEGY</div>}
      description={<div className="block mt-4 text-center text-[14px] leading-relaxed
              text-[10px]
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]
      "> In this section, we recap the current strategies to win in the marketplace.
      </div>}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_02_Start;
