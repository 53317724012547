import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_06_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_5');
  const handleNext = () => navigate('/Module_6');

  return (
   <div className="flex items-center justify-center min-h-screen">
      <div className={`w-full max-w-[600px] 
          sm:max-w-[1100px] 
          md:max-w-[600px] 
          lg:max-w-[800px] 
          xl:max-w-[1000px] 
          2xl:max-w-[1385px] 
          p-4 sm:p-6 md:p-7 lg:p-9 xl:p-8 2xl:p-16`}>
        <TransitionModule
          title={
            <div className="tracking-[8px] text-[13px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]">
              GO-TO-MARKET STRATEGY
            </div>
          }
          description={
            <div className="block mt-4 text-center text-[12px] leading-relaxed
              text-[10px] sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]">
It's essential to clearly define your go-to-market approach by identifying the most effective distribution channels to reach and engage your target market. This involves selecting the optimal methods to deliver your products or services, ensuring they resonate with your audience and achieve maximum market impact.            </div>
          }
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      </div>
    </div>
  );
};

export default Module_06_Start;
