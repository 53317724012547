import React from 'react'
import { useNavigate } from 'react-router-dom';
import NavigationButtons from './shared/NavigationButtons';

const Module_15_Start = () => {
  const navigate = useNavigate();
  const onPrevious = () => navigate("/Module_22")
  const onNext = () => navigate("/Module_15")

  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='w-[55vw] bg-card p-3 font-semibold rounded-3xl text-center text-body text-[13px]  
              text-[10px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]'>Output Report</div>
      <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
    </div>
  )
}

export default Module_15_Start