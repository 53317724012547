import React, { useEffect } from 'react';

// Reusable component for the input fields with labels
export const StrategyInput = ({ label, value = "", onChange }) => (
  <div className="flex items-center mb-4">
    <div
      className={`flex justify-center items-center bg-${
        label.includes('goal') ? 'light-purple' : 'dark-green'
      } ${label === 'Goal' ? 'text-white' : 'text-lemon-green'} 
      mr-8 font-bold py-2 px-${label === 'Goal' ? '12' : '4'} 
      text-[11px] w-[29%] rounded-xl text-center
      sm:text-[13px] sm:w-[17%] sm:rounded-xl sm:text-center
      md:text-[15px] md:w-[16%] md:text-center
      lg:text-[17px] lg:w-[13.5%] lg:text-center
      xl:text-[20px] xl:w-[10.5%] xl:text-center
      2xl:text-[25px] 2xl:text-center`}
    >
      {label}
    </div>

    <textarea
      type="text"
      value={value}
      onChange={onChange}
      className="flex-1 border-2 text-dark-green border-green-500 bg-transparent rounded-xl py-1 px-4 focus:outline-none 
      sm:py-2
      md:py-2
      lg:py-2
      xl:py-3
      2xl:py-4
      text-[10px]
      sm:text-[12px]
      md:text-[14px]
      lg:text-[16px]
      xl:text-[18px]
      2xl:text-[19px]"
    />
  </div>
);

// Reusable component for the strategy sections
export const StrategySection = ({ title, description, goals, goalStrategy, setGoalStrategy }) => {

  useEffect(() => {
    // Initialize goalStrategy state based on goals
    const initialGoalStrategy = {};
    Object.entries(goals).forEach(([key]) => {
      initialGoalStrategy[key] = [""]; // Start with one strategy per goal
    });
    setGoalStrategy(initialGoalStrategy);
  }, [goals, setGoalStrategy]);

  const addStrategy = (goalKey) => {
    setGoalStrategy(prevGoalStrategy => ({
      ...prevGoalStrategy,
      [goalKey]: [...prevGoalStrategy[goalKey], ""] // Add a new empty strategy for the specified goal
    }));
  };

  const handleStrategyChange = (goalKey, index, value) => {
    setGoalStrategy(prevGoalStrategy => {
      const updatedGoalStrategy = [...prevGoalStrategy[goalKey]];
      updatedGoalStrategy[index] = value; // Update the strategy at the specified index
      const updatedState = { ...prevGoalStrategy, [goalKey]: updatedGoalStrategy };

      return updatedState;
    });
  };

  return (
    <div className="mt-4">
      <div className='text-light-green font-semibold ml-4 
            text-[13px]
            sm:text-[14px]
            md:text-[17px]
            lg:text-[20px]
            xl:text-[23px]
            2xl:text-[25px]'>{title}</div>
      <div className='text-dark-green my-4 ml-4 
            text-[13px]
            sm:text-[14px]
            md:text-[17px]
            lg:text-[20px]
            xl:text-[23px]
            2xl:text-[25px]'>{description}</div>
      <hr className="border-t-1 border-black mx-4" />
      <div className="flex flex-col p-4">
        <p className="text-dark-green my-4 
            text-[13px]
            sm:text-[14px]
            md:text-[17px]
            lg:text-[20px]
            xl:text-[23px]
            2xl:text-[25px]">What would be your winning strategy?</p>

        {Object.entries(goals).map(([key, value]) => (
          <div key={key}>
            <StrategyInput 
              label={key.split("_").join(" ")} 
              value={Object.entries(value).map(([key, value])=> value).join("")} // Use goalStrategy for value
              onChange={(e) => handleStrategyChange(key, 0, e.target.value)} 
            />

            {/* Render additional strategy fields */}
            {goalStrategy[key]?.slice(1).map((strategy, index) => (
              <StrategyInput 
                key={index + 1} 
                label={`Strategy ${index + 1}`} 
                value={strategy} 
                onChange={(e) => handleStrategyChange(key, index + 1, e.target.value)} 
              />
            ))}
            <button
              className="bg-light-green text-white font-bold rounded-xl my-4 font-bold py-2  
              text-[13px] w-[30%]
              sm:text-[13px] sm:w-[17%]
              md:text-[15px] md:w-[16.5%]
              lg:text-[17px] lg:w-[13.5%]
              xl:text-[20px] xl:w-[10.5%]     
              2xl:text-[25px] 2xl:w-[10.5%]"
              onClick={() => addStrategy(key)}
            >
               Add Strategy
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};