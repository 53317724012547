import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_04_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_3b');
  const handleNext = () => navigate('/Module_4');

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className={`w-full max-w-[600px] 
          sm:max-w-[1100px] 
          md:max-w-[600px] 
          lg:max-w-[800px] 
          xl:max-w-[1000px] 
          2xl:max-w-[1385px] 
          p-4 sm:p-6 md:p-7 lg:p-9 xl:p-8 2xl:p-16`}>
        <TransitionModule
          title={
            <div className="tracking-[8px] text-[12px] 
              sm:text-[15px] 
              md:text-[16px] 
              lg:text-[20px] 
              xl:text-[20px] 
              2xl:text-[35px]">
              OPERATING EFFICIENCY
            </div>
          }
          description={
            <div className="block mt-4 text-center text-[12px] leading-relaxed
              text-[10px] sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]">
              This section evaluates how effectively the company executes its plans in the marketplace. Operating discipline is often the key factor that separates success from failure and can be even more critical than strategy itself.
            </div>
          }
          onPrevious={handlePrevious}
          onNext={handleNext}
        />
      </div>
    </div>
  );
};

export default Module_04_Start;
