import React from 'react';

const Header = () => {
  return (
    <div>
      <div className="bg-purple-500 flex justify-center items-center px-4
      h-[50px]  
      sm:h-[50px]
      md:h-[60px]
      lg:h-[70px]
      xl:h-[80px]
      2xl:h-[107px]">
        <div className="text-frame font-bold mr-8 text-[20px]
            sm:text-[22px]
            md:text-[24px]
            lg:text-[25px]
            xl:text-[28px]
            2xl:text-[33px]
            ">The Frame</div>
      </div>
    </div>
  );
};

export default Header;
