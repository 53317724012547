import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios"; // Import the axios instance
import ModuleTemplate from "./shared/ModuleTemplate";
import toast, { Toaster } from "react-hot-toast";

const Module_07c = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [data, setData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [updateItemId, setUpdateItemId] = useState([]);
  const [postItemId, setPostItemId] = useState([]);

  useEffect(() => {
    // it gives the objects with null answers
    axiosInstance
      .get(`module/competitive_landscape?otp=${savedOtp}&section_id=3`)
      .then((response) => {
        console.log("response:", response);
        setData(response.sort((a, b) => a.id - b.id));
      })
      .catch((error) => console.log("error:", error));

    // it gives answers also
    axiosInstance
      .get(`module/competitive_landscape_value?otp=${savedOtp}&section_id=3`)
      .then((response) => {
        console.log("values api response:", response);

        if (response.length > 0) {
          setData(response.sort((a, b) => a.id - b.id));
        }
      })
      .catch((error) => console.log("error:", error));
  }, []);

  // useEffect(() => {
  //   console.log('data:', data);
  //   console.log('updateData:', updateData);
  //   console.log('updateItemId:', updateItemId);
  //   console.log('postItemId:', postItemId);
  //   console.log('postData:', postData);
  // }, [data, postData, updateData, updateItemId, postItemId]);

  useEffect(() => {
    // Filter data based on updateItemId array and update updateData state
    const updatedItems = data
      .filter((item) => updateItemId.includes(item.id)) // Check if item.id is in updateItemId
      .map((item) => ({
        section_id: 3,
        question_id: item.id,
        competitive_landscape_value: item.competitive_landscape_value,
      }));

    setUpdateData(updatedItems);
  }, [updateItemId, data]);

  useEffect(() => {
    // Filter data based on postItemId array and update updateData state
    const updatedItems = data
      .filter((item) => postItemId.includes(item.id)) // Check if item.id is in postItemId
      .map((item) => ({
        section_id: 3,
        question_id: item.id,
        competitive_landscape_value: item.competitive_landscape_value,
      }));

    setPostData(updatedItems);
  }, [postItemId, data]);

  const handleChange = (index, value) => {
    console.log("index:", index);

    const updatedData = [...data];
    updatedData[index] = {
      ...updatedData[index],
      competitive_landscape_value: value,
    };
    setData(updatedData);

    if (data.every((item) => item.competitive_landscape_value !== null)) {
      console.log("Data has non-null values");
      setUpdateItemId((prevState) => {
        if (!prevState.includes(data[index].id)) {
          return [...prevState, data[index].id];
        }
        return prevState;
      });
    } else {
      console.log("Data has null values");
      setPostItemId((prevState) => {
        if (!prevState.includes(data[index].id)) {
          return [...prevState, data[index].id];
        }
        return prevState;
      });
    }
  };

  const handleSubmit = async () => {
    if (
      data.every(
        (item) =>
          item.competitive_landscape_value !== null &&
          item.competitive_landscape_value !== ""
      )
    ) {
      if (updateData.length > 0) {
        axiosInstance
          .put(`module/competitive_landscape?otp=${savedOtp}`, updateData)
          .then((response) => {
            console.log("PUT response:", response);
          })
          .catch((error) => console.log("error:", error));
      }
      if (postData.length > 0) {
        axiosInstance
          .post(`module/competitive_landscape_value?otp=${savedOtp}`, postData)
          .then((response) => {
            console.log("POST response:", response);
          })
          .catch((error) => console.log("error:", error));
      }
      navigate("/Module_07d");
    } else {
      toast.error("Please answer all questions.");
    }
  };

  return (
    <div>
      <ModuleTemplate
        title="03: Ease of Substitution"
        titleClassName="text-dark-green"
        bgColor="bg-ease"
        questions={data.map((item) => item.question)}
        answers={data.map((item) =>
          item.competitive_landscape_value !== null
            ? item.competitive_landscape_value
            : ""
        )}
        onPrevious={() => navigate("/Module_07b")}
        onNext={handleSubmit} // Trigger form submission when clicking the "Next" button
        onChange={handleChange} // Pass the change handler to ModuleTemplate
      />
      <Toaster position="top-right" reverseOrder={false} />{" "}
      {/* Add Toaster component */}
    </div>
  );
};

export default Module_07c;
