import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_14_Start = () => {
  const navigate = useNavigate();
  const onPrevious = () => navigate('/Module_13');
  const onNext = () => navigate('/Module_14');

  return (
    <TransitionModule
      title={<div className="tracking-[8px] text-[15px] 
              sm:text-[15px] 
              md:text-[18px] 
              lg:text-[20px] 
              xl:text-[25px] 
              2xl:text-[35px]">MEASURES</div>}
      description={<div className='block mt-4 text-center text-[13px] leading-relaxed
              text-[10px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]'>Measures  helps provide numerical targets for the strategies to achieve to be termed successful.</div>}
      onPrevious={onPrevious}
      onNext={onNext}
    />
  );
};

export default Module_14_Start;
