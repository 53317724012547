import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_08_Start = () => {
    const navigate = useNavigate();
    const handlePrevious = () => navigate('/Module_07e');
    const handleNext = () => navigate('/Module_16');

    // Description content
    const descriptionContent = [
        '1. Personal Statement',
        '2. Vision Statement',
        '3. Goal Setting'

    ];

    return (
        <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-[600px]
            sm:max-w-[1100px]
            md:max-w-[600px]
            lg:max-w-[800px]
            xl:max-w-[1200px]
            2xl:max-w-[1385px]
            p-4 
            sm:p-6 
            md:p-7
            lg:p-9
            xl:p-8
            2xl:p-15">
    <TransitionModule
    title={
      <div className="tracking-[8px] text-[13px] 
              sm:text-[15px] 
              md:text-[16px] 
              lg:text-[20px] 
              xl:text-[22px] 
              2xl:text-[35px]
      ">
       SETTING THE DIRECTION
        </div>}
    description={
      <div>
      <p className='my-2 
       text-[12px]
       sm:text-[14px] 
       md:text-[15px] 
       lg:text-[18px] 
       xl:text-[20px]  
       2xl:text-[30px]'>
            In this section, we will focus on establishing the following:
          </p>
          <ul className="list-inside pl-0 mt-6  text-[12px]
            sm:text-[14px] 
            md:text-[15px] 
            lg:text-[18px] 
            xl:text-[20px]  
            2xl:text-[30px]">
            {descriptionContent.map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </div>
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
    </div>
    </div>
    );
};

export default Module_08_Start;
