import React, { useState, useEffect } from "react";
import Timeline from "./shared/Timeline";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios"; // Import the axios instance

const Module_09_b = () => {
  const completedSteps = ["PLAYING FIELD"];
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");

  // State to hold the user's input and initial data from API
  const [playingFieldDescription, setPlayingFieldDescription] = useState("");

  // Fetch data when component mounts
  useEffect(() => {
    const fetchPlayingFieldData = async () => {
      try {
        // Fetch data from API
        const response = await axiosInstance.get(
          `module/choices-of-winning-strategy?otp=${savedOtp}`
        );

        // Set the playing field description from the API response
        if (response?.["playing_field_description"]) {
          setPlayingFieldDescription(response?.["playing_field_description"]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPlayingFieldData();
  }, [savedOtp]);

  const onPrevious = () => navigate("/Module_09");

  const onNext = async () => {
    const data = {
      module_id: 9,
      playing_field_description: playingFieldDescription,
    };
    try {
      // Attempt to submit the data to the API
      await axiosInstance.post(
        `module/choices-of-winning-strategy?otp=${savedOtp}`,
        data
      );
      console.log("Data posted successfully:", data); // Log success message
    } catch (error) {
      console.error("Error submitting data:", error);
    }

    // Move to the next screen regardless of success/failure
    navigate("/Module_09_c");
  };

  const renderTableCell = () => (
    <td
      className="border border-green-500 p-2 text-[10px]
              sm:text-[10px]
              md:text-[12px]
              lg:text-[14px]
              xl:text-[16px]
              2xl:text-[20px]"
      contentEditable="true"
      style={{ verticalAlign: "top", textAlign: "left"}} // Ensure text starts from the top-left
    ></td>
  );

  
  const renderTableRow = (label) => (
    <tr>
      <td
        className="border border-green-500  p-2 font-semibold text-center mb-4 
            text-[5px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]">
        {label}
      </td>
      {Array.from({ length: 6 }).map((_, index) => (
        <React.Fragment key={index}>{renderTableCell()}</React.Fragment>
      ))}
    </tr>
  );

  return (
    <div className="flex flex-col p-12">
      <p
        className="font-bold inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]">
        Choices of Winning Strategy
      </p>
      <div className="mb-8">
        <p
          className="bg-light-purple p-2 text-body text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]">
          Module 10
        </p>
      </div>

      <Timeline completedSteps={completedSteps} />

      <div className="flex flex-col gap-2">
        <p
          className="bg-light-green p-2 text-body text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          STEP 01: Playing Field
        </p>
        <p
          className="text-dark-green text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          In this step, the leadership makes the choices of the playing field
          for the company. It includes Geography, Sectors, Products, Services,
          and Customers. As the company develops its choice of 'Playing Field,'
          it is imperative to consider value creation dimensions in identifying
          the niche for competing. Where you are playing today and if you would
          like to change or expand in the future. When we speak of competitive
          advantage, it must be imbibed in every decision that we make. Getting
          a competitive advantage in the playing field now or in the future is
          key for the long-term sustainable growth of the company.
        </p>

        <div className="my-8">
          <table
            className="table-fixed w-full mb-4 border-collapse border border-green-500 text-dark-green text-center  
            text-[5px]
            sm:text-[7px]
            md:text-[9px]
            lg:text-[11px]
            xl:text-[13px]
            2xl:text-[15px]">
            <thead>
              <tr>
                <th className="border border-green-500 p-2"></th>
                <th className="border border-green-500 p-2">Products/Services</th>
                <th className="border border-green-500 p-2">Categories</th>
                <th className="border border-green-500 p-2">Geography</th>
                <th className="border border-green-500 p-2">Customers</th>
                <th className="border border-green-500 p-2">Channels</th>
                <th className="border border-green-500 p-2 w-1/6">
                 Differentiation or Cost
                </th>
              </tr>
            </thead>
            <tbody>
              {renderTableRow("Products / Services")}
              {renderTableRow("Expansion")}
              {renderTableRow("CA/P/CD*")}
            </tbody>
          </table>

          <div
            className="py-2 px-2 rounded-full bg-dark-green inline-block text-lemon-green text-[5px]
            sm:text-[8px]
            md:text-[9px]
            lg:text-[11px]
            xl:text-[13px]
            2xl:text-[15px]">
            *CA - Competitive Advantage, P - Parity, and CD - Competitive
            Disadvantage
          </div>
        </div>

        <p
          className="text-dark-green font-bold my-3 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          Articulate your playing field using the description above.
        </p>

        {/* Textarea for playing field description */}
        <textarea
          className="bg-transparent text-dark-green p-3 mb-20 rounded-lg border border-light-green w-full text-[10px]
    sm:text-[12px]
    sm:h-[95px]
    md:text-[14px]
    md:h-[120px]
    lg:text-[16px]
    lg:h-[130px]
    xl:text-[18px]
    xl:h-[115px]
    2xl:text-[19px]
    2xl:h-[155px]"
          value={playingFieldDescription}
          onChange={(e) => setPlayingFieldDescription(e.target.value)} // Update state on input change
        ></textarea>
      </div>

      <div className="flex justify-end mt-4">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
};

export default Module_09_b;
