import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import Strategy from "./shared/Strategy2";
import { FiDownload } from "react-icons/fi";
import axiosInstance from "../utility/axios"; 
import parse from 'html-react-parser';

const Module_15 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null); // State to hold the API data
  const [visionValue, setVisionValue] = useState(""); // State to hold vision data
  const [objectiveValue, setObjectiveValue] = useState(""); // State to hold objective data
  const [inputs, setInputs] = useState([]); // State to hold user inputs
  const savedOtp = sessionStorage.getItem("otp");
  const contentToPrint = useRef(null);

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  // Fetch the data for vision and objective
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch final output data
        const finalOutputResponse = await axiosInstance.get(
          `module/final_output?otp=${savedOtp}`
        );
        setData(finalOutputResponse); // Update state with the fetched data
        setInputs(finalOutputResponse.strategies.one_year_strategy.map(() => "")); // Initialize inputs

        // Fetch vision data
        const visionResponse = await axiosInstance.get(
          `module/setting-the-direction?otp=${savedOtp}&name=vision`
        );
        setVisionValue(visionResponse.value); // Set vision data

        // Fetch objective data
        const objectiveResponse = await axiosInstance.get(
          `module/setting-the-direction?otp=${savedOtp}&name=objective`
        );
        setObjectiveValue(objectiveResponse.value); // Set objective data

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [savedOtp]);

  const handleInputChange = (index, value) => {
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    setInputs(updatedInputs);
  };

  const handleSave = async () => {
    handlePrint(null, () => contentToPrint.current);
  };

  // Define strategies data
  const strategies = [
    { number: "Your Vision Statement", value: visionValue },
    { number: "Your Playing Field", value: objectiveValue },
  ];

  // If data is not yet fetched, display a loading message or spinner
  if (!data) {
    return <div>Loading...</div>;
  }

  // Convert goals and strategies to arrays
  let oneYearGoals = Object.values(data.goals.one_year_goal || {}).flat();
  let fiveYearGoals = Object.values(data.goals.five_year_goals || {}).flat();
  oneYearGoals = oneYearGoals.join("</li><li>")
  fiveYearGoals = fiveYearGoals.join("</li><li>")
  const allGoals = [oneYearGoals, fiveYearGoals];

  let oneYearStrategies = data.strategies.one_year_strategy || [];
  let fiveYearStrategies = data.strategies.five_year_strategy || [];
  
  // Process strategies to preserve line breaks
  const processStrategies = (strategies) => {
    return strategies.map(strategy => strategy.replace(/\n/g, '<br>')).join("</li><li>");
  };

  oneYearStrategies = processStrategies(oneYearStrategies);
  fiveYearStrategies = processStrategies(fiveYearStrategies);

  const allStrategies = [oneYearStrategies, fiveYearStrategies];

  // Prepare measures and competencies values
  const measures = Object.values(data.measures || {});
  const competencies = data.competencies_values || "";

  return (
    <div className="flex flex-col p-4" ref={contentToPrint}>
      <p
        className="text-black font-bold inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]">
        Output Report
      </p>
      <div className="bg-light-green p-2 mb-4">
        <h3
          className="text-body h-[30px] text-[18px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[42px]">
          Module 14
        </h3>
      </div>
      <div
        className="text-dark-green mt-6 text-left mb-4 font-base text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
        <Strategy strategies={strategies} onInputChange={handleInputChange} />
      </div>
      <div className="mb-20">
        <table
          className="border border-green-400 text-left border-collapse text-dark-green w-[100%] min-w-[100px] p-3 mt-8 mb-4 text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[20px]
            2xl:text-[20px]">
          <thead>
            <tr>
              <th className="border border-green-400 px-4 py-2 w-[25%]">Objectives</th>
              <th className="border border-green-400 px-4 py-2 w-[30%]">Strategies</th>
              <th className="border border-green-400 px-4 py-2 w-[20%]">Measures</th>
              <th className="border border-green-400 px-4 py-2 w-[25%]">
                Core Competencies
              </th>
            </tr>
          </thead>
          <tbody className="text-[10px]
          sm:text-[12px]
          md:text-[14px]
          lg:text-[16px]
          xl:text-[18px]
          2xl:text-[19px]">
            {allGoals.map((goal, index) => (
              <tr key={index}>
                <td className="border border-green-400 p-4 align-top">
                  <ul className="m-0 p-0 list-none"><li>{parse(goal)}</li></ul>
                </td>
                <td className="border border-green-400 p-4 align-top">
                  <ul className="m-0 p-0 list-none"><li>{parse(allStrategies[index])}</li></ul>
                </td>
                <td className="border border-green-400 p-4 align-top">
                  {measures[index] && measures[index].split('\n').map((measureItem, i) => (
                    <div key={i}>{measureItem}</div>
                  ))}
                </td>
                <td className="border border-green-400 p-4 align-top">
                  {index === 0 ? parse(competencies.replace(/\n/g, '<br>')) : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end mt-9">
          <button
            className="no-print bg-red-800 text-body py-1 px-3 rounded-3xl flex items-center text-left mb-4 text-[7px]
            sm:text-[9px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[23px]"
            onClick={handleSave} // Handle save and print
          >
            Save Data <FiDownload className="ml-2 text-lemon-green" />
          </button>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <NavigationButtons
          onPrevious={() => navigate("/Module_15_Start")}
          onNext={() => {}}
          nextButtonText="Finish"
        />
      </div>
    </div>
  );
};

export default Module_15;
