import React from "react";
import NavigationButtons from "./NavigationButtons";

const QuestionTextarea = ({ question, index, onChange, answer }) => (
  <div className="mb-6">
    <p
      className="text-dark-green font-semibold text-dark-green mt-8 text-left mb-4 text-[12px]
            sm:text-[15px]
            md:text-[16px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
    >
      {question}
    </p>
    <textarea
      className="w-full p-2 border border-dark-green rounded-lg bg-transparent  max-w-[600px]
            sm:max-w-[580px]
            md:max-w-[700px]
            lg:max-w-[950px]
            xl:max-w-[1340px]
            2xl:max-w-[100%]
            p-3 
            sm:p-3 
            md:p-3
            lg:p-3
            xl:p-4
            2xl:p-5
            text-[12px]
            sm:text-[12px] 
            md:text-[14px] 
            lg:text-[16px] 
            xl:text-[18px]  
            2xl:text-[22px]"
      onChange={(e) => onChange(index, e.target.value)} // Call onChange with index and value
      value={answer}
    />
  </div>
);

const ModuleTemplate = ({
  title,
  titleClassName,
  bgColor,
  description,
  questions,
  onPrevious,
  onNext,
  onChange,
  answers
}) => {
  return (
    <div className="px-12 mt-8">
      <h1
        className="text-black font-extrabold  sm:mb-0 inline text-[14px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]"
      >
        Competitive Landscape
      </h1>
      <div className={`${bgColor} text-body p-2 mb-4`}>
        <h2
          className={`text-[12px]
            sm:text-[14px]
            md:text-[16px]
            lg:text-[18px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]" ${titleClassName}`}
        >
          {title}
        </h2>
      </div>
      {description && (
        <div
          className="text-dark-green mb-10 text-dark-green text-dark-green mt-8 text-left mb-4 text-[13px]
            sm:text-[15px]
            md:text-[17px]
            lg:text-[18px]
            xl:text-[20px]
            2xl:text-[25px]"
        >
          {description}
        </div>
      )}
      <div className="text-dark-green">
        {questions.map((question, index) => (
          <QuestionTextarea
            key={index}
            question={question}
            index={index}
            onChange={onChange} // Pass onChange to QuestionTextarea
            answer= {answers[index] ? answers[index] : ""}
          />
        ))}
      </div>
      <div className="mt-28">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
};

export default ModuleTemplate;
