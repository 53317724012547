import React from 'react';
import CustomSlider from './CustomSlider';

const SliderSection = ({ label, customLabel, onChange, selectedValue, onCustomLabelChange }) => {
  const handleSliderChange = (value) => {
    onChange(value);
  };

  return (
    <div className="mb-6 sm:mb-8 flex flex-col">
      {/* Label */}
      <h4 className="text-dark-green   font-base mb-1  text-[12px]
            sm:text-[16px]
            md:text-[16px]
            lg:text-[22px]
            xl:text-[18px]
            2xl:text-[25px]">{label}</h4>

      <div className="flex">
        {/* Slider */}
        <div className="w-full pr-2 sm:pr-4">
          <CustomSlider onChange={handleSliderChange} selectedValue={selectedValue} />
        </div>
      </div>

      {/* Editable Custom Label */}
      {customLabel && (
        <div className="mt-2">
          <input
            type="text"
            placeholder={customLabel}
            onChange={(e) => onCustomLabelChange(e.target.value)}
            className="w-32 h-8 mt-4 bg-transparent border-2 border-teal-100 text-dark-green rounded-full flex items-center justify-left text-xs sm:text-sm placeholder-center placeholder:text-dark-green"
            style={{
              textAlign: 'left',  // Align text to the left
              paddingLeft: '12px'  // Add padding to create space from the left
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SliderSection;
