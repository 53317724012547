import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utility/axios'; // Import the axios instance
import InputWithLabel from './shared/InputWithLabel';
import NavigationButtons from './shared/NavigationButtons';
import Spinner from "./shared/Spinner";

const Module_02 = () => {
  const savedOtp = sessionStorage.getItem('otp');
  const navigate = useNavigate(); // Hook to use navigation
  const [strategies, setStrategies] = useState([{ strategy_id: 1, strategy: '' }]);
  const [newStrategies, setNewStrategies] = useState([]);
  const [responseLength, setResponseLength] = useState(0);
  const [updatedStrategies, setUpdatedStrategies] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
setLoading(true)
    axiosInstance.get(`module/strategy_value?strategy_id=1&otp=${savedOtp}`)
      .then((response) => {
        console.log('response:', response);
        setResponseLength(response.length);
        
        if (response.length > 0) {
          setStrategies(response.sort((a, b) => a.id - b.id));
        }
        
      }).catch((error) => console.log('error:', error))
    .finally(() => setLoading(false));

  }, []);

  // Event handlers for navigation buttons
  const handlePrevious = () => {
    navigate('/Module_02_Start');
  };

  const handleNext = async () => {
    try {
      // Ensure all strategies have unique strategy_id values
      const filteredNewStrategies = newStrategies.filter(strategy => strategy.strategy.trim() !== '');
      axiosInstance.post(`module/save_strategy_value?otp=${savedOtp}`, newStrategies)
      .then((response)=> console.log('response:', response)).catch((error)=> console.log('error:', error))

      if (updatedStrategies.length > 0) {
        axiosInstance.put(`module/save_strategy_value?otp=${savedOtp}`, updatedStrategies)
        .then((response)=> console.log('response:', response)).catch((error)=> console.log('error:', error))
      }
      navigate('/Module_03_Start');
    } catch (error) {
      console.error('error:', error);
    }
  };

  // Handler for updating strategy values
  // const handleStrategyChange = (index, value, strategy) => {
  //   console.log(`Strategy ${index + 1} updated to:`, value); // Debugging the onChange event
  //   console.log('strategy:', strategy);
  //   if (strategy.id) {
  //     const newUpdatedStrategies = [...updatedStrategies, {id: strategy.id, strategy_id: 1, strategy: value}]
  //     setUpdatedStrategies(newUpdatedStrategies)
  //     console.log('updatedStrategies:', updatedStrategies);
      
  //   } 
  //     const newAddedStrategies = [...strategies];
  //     newAddedStrategies[index] = { ...strategies[index], strategy: value };
  //     setStrategies(newAddedStrategies);
    
    

  //   // const newAddedStrategies = [...strategies];
  //   // newAddedStrategies[index] = { ...strategies[index], strategy: value };
  //   // setStrategies(newAddedStrategies);
  // };

  const handleStrategyChange = (index, value, strategy) => {
    console.log(`Strategy ${index + 1} updated to:`, value); // Debugging the onChange event
    console.log('strategy:', strategy);
  
    // Update strategies state
    const newAddedStrategies = [...strategies];
    newAddedStrategies[index] = { ...strategies[index], strategy: value };
    setStrategies(newAddedStrategies);
  
    // Update updatedStrategies state
    if (strategy.id) {
      // Check if the strategy is already in the updatedStrategies array
      const updated = updatedStrategies.some(upd => upd.id === strategy.id);
  
      if (updated) {
        // If it exists, update the existing entry
        const newUpdatedStrategies = updatedStrategies.map(upd => 
          upd.id === strategy.id ? { ...upd, strategy: value } : upd
        );
        setUpdatedStrategies(newUpdatedStrategies);
      } else {
        // If it doesn't exist, add a new entry
        const newUpdatedStrategies = [...updatedStrategies, { id: strategy.id, strategy_id: 1, strategy: value }];
        setUpdatedStrategies(newUpdatedStrategies);
      }
  
      console.log('updatedStrategies:', updatedStrategies);
    }
  };
  

  // Handler for adding more strategies
  const handleAddMore = () => {
    setStrategies([...strategies, { strategy_id: 1, strategy: '' }]);
  };

  useEffect(() => {
    console.log('strategies:', strategies);
    console.log('updatedStrategies:', updatedStrategies);

    console.log('newStrategies:', newStrategies);
    
    console.log('responseLength:', responseLength);
  }, [strategies, newStrategies, updatedStrategies]);

  useEffect(() => {
    // Ensure the calculation only happens when strategies and responseLength are updated
    if (strategies.length > responseLength) {
      const sliceIndex = responseLength; // Calculate where to start slicing
      const newAddedStrategies = strategies.slice(sliceIndex) // Get the newly added strategies
        .filter(strategy => strategy.strategy.trim() !== ''); // Filter out empty strategies
      setNewStrategies(newAddedStrategies);
    }
  }, [strategies, responseLength]); // Re-run this effect when strategies or responseLength changes

  return (
    <div className="flex flex-col items-start px-8 py-4 max-w">
      {/* Question Text */}
      <div className="text-dark-green mt-12 text-left text-md mb-4 text-[15px]
            sm:text-[17px]
            md:text-[18px]
            lg:text-[19px]
            xl:text-[20px]
            2xl:text-[25px]">
        What are your current strategies?
      </div>

      {/* Strategy Inputs */}
      <div className="flex flex-col gap-2 w-full">
        {
          loading ? (
            <Spinner />
          ) : (
            strategies.map((strategy, index) => (
              <InputWithLabel
                key={index}
                label={`Strategy ${index + 1}`}
                value={strategy.strategy}
                onChange={(e) => handleStrategyChange(index, e.target.value, strategy)}
              />
            ))
          )
        }
      </div>

      {/* Button to Add More */}
      <div className="flex justify-start mt-6 mb-20 text-[14px]
            sm:text-[17px]
            md:text-[18px]
            lg:text-[18px]
            xl:text-[18px]
            2xl:text-[23px]">
        <button
          onClick={handleAddMore}
          className="bg-light-green text-body px-4 py-1 rounded-full"
        >
          + Add Strategies
        </button>
      </div>

      {/* Navigation Buttons */}
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_02;
