import React, { useState, useEffect } from 'react';

const CustomSlider = ({ onChange, selectedValue }) => {
  const [selectedCircle, setSelectedCircle] = useState(null); // Track the selected circle

  const handleCircleClick = (index) => {
    setSelectedCircle(index); // Set the clicked circle as selected
    onChange(index); // Pass the selected index to the parent component
  };

  useEffect(()=>{
    setSelectedCircle(selectedValue)
  }, [selectedValue])

  return (
    <div className="relative mt-2 sm:mt-4">
      {/* The horizontal line */}
      <div className="relative w-full h-1 sm:h-2 bg-teal-500 rounded-full flex items-center justify-between">
        {/* Circles */}
        <div
          className={`w-4 h-4 sm:w-6 sm:h-6 border rounded-full absolute left-3 transform -translate-x-1/2 cursor-pointer ${selectedCircle === 0 ? 'bg-teal-500 border-teal-700' : 'bg-body border-teal-700'}`}
          onClick={() => handleCircleClick(0)}
        ></div>
        <div
          className={`w-4 h-4 sm:w-6 sm:h-6 border rounded-full absolute left-1/2 transform -translate-x-1/2 cursor-pointer ${selectedCircle === 1 ? 'bg-teal-500 border-teal-700' : 'bg-body border-teal-700'}`}
          onClick={() => handleCircleClick(1)}
        ></div>
        <div
          className={`w-4 h-4 sm:w-6 sm:h-6 border rounded-full absolute right-3 transform translate-x-1/2 cursor-pointer ${selectedCircle === 2 ? 'bg-teal-500 border-teal-700' : 'bg-body border-teal-700'}`}
          onClick={() => handleCircleClick(2)}
        ></div>
      </div>

      {/* Labels under the circles */}
      <div className="flex justify-between mt-3 text-teal-700 text-[8px]
            sm:text-[14px]
            md:text-[13px]
            lg:text-[18px]
            xl:text-[15px]
            2xl:text-[20px]">
        <div className="text-center">Disadvantage</div>
        <div className="text-center mr-3">Parity</div>
        <div className="text-center">Advantage</div>
      </div>
    </div>
  );
};

export default CustomSlider;
