import React, { useState, useEffect } from "react";
import axiosInstance from "../utility/axios"; // Import the axios instance
import { useNavigate } from "react-router-dom";
import NavigationButtons from "./shared/NavigationButtons";
import Table from "./shared/Table";
import SliderSection from "./shared/SliderSection";
import ElaborateInput from "./shared/ElaborateInput";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "./shared/Spinner";

const Module_3b = () => {
  const savedOtp = sessionStorage.getItem("otp");
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState([]);
  const [data, setData] = useState([]);
  const [postItemId, setPostItemId] = useState([]);

  useEffect(() => {
    axiosInstance
      .get(
        `module/current-situation-values?otp=${savedOtp}&module_id=${3}&heading=Cost`
      )
      .then((response) => {
        console.log("response:", response);
        setData(response.sort((a, b) => a.id - b.id));
        setLoading(false);
      })
      .catch((error) => {
        console.error("error:", error);
        setLoading(false);
      });
  }, [savedOtp]);

  useEffect(() => {
    console.log("data:", data);
    console.log("postItemId:", postItemId);
  }, [data, postItemId]);

  const navigate = useNavigate(); // Hook to use navigation

  const handleSliderChange = (item, index, value) => {
    console.log("item:", item);

    // Updating data in a similar way
    setData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], selected_value: value };
      return newApiData;
    });

    setPostItemId((prevState) => {
      if (!prevState.includes(data[index].id)) {
        return [...prevState, data[index].id];
      }
      return prevState;
    });
  };

  const handleInputChange = (item, index, text) => {
    console.log("item:", item);

    setPostItemId((prevState) => {
      if (!prevState.includes(data[index].id)) {
        return [...prevState, data[index].id];
      }
      return prevState;
    });

    // Updating data in a similar way
    setData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], descriptions: text };
      return newApiData;
    });
  };

  useEffect(() => {
    // Filter data based on postItemId array and update postData state
    const updatedItems = data
      .filter((item) => postItemId.includes(item.id)) // Check if item.id is in postItemId
      .map((item) => ({
        situation_id: item.id,
        selected_value: item.selected_value,
        descriptions: item.descriptions,
      }));

    setPostData(updatedItems);
  }, [postItemId, data]);

  // Event handlers for navigation buttons
  const handlePrevious = () => navigate("/Module_3");

  const handleNext = () => {
    try {
      if (data.every((item) => item.selected_value !== null)) {
        console.log("Data has no null values");

        if (postData.length > 0) {
          console.log("Making a POST request");

          axiosInstance
            .post(`module/save_situation_value?otp=${savedOtp}`, postData)
            .then((response) => {
              console.log("POST response:", response);
            })
            .catch((error) => {
              console.error("error:", error);
            });
        }

        navigate("/Module_04_Start");
      } else {
        console.log("Data has null values");
        toast.error("Please select an option for all questions.");
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div className="max-w-full mx-auto p-4 sm:p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-2">
        <h2
          className="text-dark-green font-extrabold inline text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[30px]"
        >
          Current Situation
        </h2>
        <h2
          className="text-dark-green font-extrabold inline text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[30px]"
        >
          Goal (1-3 years)
        </h2>
      </div>

      {/* Title */}
      <div className="bg-cost p-2 mb-4">
        <h3
          className="text-body h-[28px] text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]"
        >
          Cost
        </h3>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        /* Sliders Section */
        <div className="mb-20">
          <Table
            data={data.map((item, index) => [
              <SliderSection
                key={`slider-${index}`}
                label={item.sub_heading}
                onChange={(value) => handleSliderChange(item, index, value)}
                selectedValue={data[index] && data[index].selected_value}
              />,
              <ElaborateInput
                key={`input-${index}`}
                onChange={(text) => handleInputChange(item, index, text)}
                value={data[index] && data[index].descriptions}
              />,
            ])}
          />
        </div>
      )}

      {/* Navigation Buttons */}
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Module_3b;
