// src/components/shared/QuestionTextarea.js
import React, { useState, useRef, useEffect } from 'react';

const QuestionTextarea = ({ question, suggestion, handleGoalChange, goalIndex, option, initValue = "" }) => {
  const [text, setText] = useState(initValue);
  const textareaRef = useRef(null);
  // Scroll to the bottom when new content is added
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [text]);
  // This function is responsible for updating the state and notifying the parent about changes
  const updateText = (newText) => {
    setText(newText);
    handleGoalChange(goalIndex, newText, option); // Notify the parent component
  };

  const handleFocus = (event) => {
    if (text.trim() === '') {
      updateText('• ');
  }
};
  

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      updateText('');
    } else if (value.length === 1 && value !== '• ') {
      updateText('• ' + value);
    } else {
      updateText(value);
    }
    console.log('e.target.value)', e.target.value)
    handleGoalChange(goalIndex, e.target.value, option)
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateText((prevText) => `${prevText}\n• `);
    } else if (e.key === 'Backspace' && text === '• ') {
      e.preventDefault();
      updateText('');
    }
  };

  return (
    <div className="relative mb-6">
      <p className="text-dark-green font-semibold mb-2">{question}</p>
      <textarea
        ref={textareaRef} // Ref to track the textarea's scroll position
        className="w-full text-dark-green rounded-2xl bg-transparent border border-dark-green
                           text-[10px]
                           sm:text-[12px]
                           sm:h-[95px]
                           md:text-[14px]
                           md:h-[120px]
                           lg:text-[16px]
                           lg:h-[130px]
                           xl:text-[18px]
                           xl:h-[115px]
                           2xl:text-[19px]
                           2xl:h-[145px]"
        value={text}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        style={{
          maxHeight: '150px', // Set a maximum height
          overflowY: 'auto', // Enable vertical scroll when text exceeds height
          resize: 'none' // Disable manual resizing
        }}
      />
      {suggestion && (
        <div className="absolute top-[-3.20rem] right-[140px] mt-2 group">
          <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
            <span className="absolute font-semibold text-[10px] top-[1.10rem] -right-[130px]
            sm:text-[12px]
            sm:-right-[135px]
            sm:-top-[-1.50rem]
            md:-right-[135px]
            md:text-[14px]
            md:mt-[10px]
            md:-top-[-.40rem]
            lg:text-[16px] 
            lg:mt-[10px]
            lg:-right-[135px]
            xl:text-[16px]
            xl:mt-[10px]
            2xl:text-[20px]">Suggestions</span>
          </div>
          <div className="hidden group-hover:block absolute top-0 right-0 w-[200px] bg-white text-dark-green border p-2 rounded-lg shadow-lg text-xs sm:text-sm">
            {suggestion.map((suggestionItem, i) => (
              <div key={i}>
                <p>{suggestionItem}</p>
                {i < suggestion.length - 1 && <hr className="my-2 border-t-2 border-lemon-green" />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionTextarea;