import React from 'react'
import Timeline2 from './shared/Timeline2'
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from 'react-router-dom';

const Module_09_c = () => {

  const navigate = useNavigate();
  const onPrevious = () => navigate("/Module_09_b") 
  const onNext = () => navigate("/Module_09_d")

  const completedSteps = ["PLAYING FIELD", "WINNING STRATEGY"];
  return (
    <div className='flex flex-col p-12'>
      <p className='font-bold inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]'>Choices of Winning Strategy</p>
      <div className='mb-8'>
        <p className='bg-light-purple p-2 text-body text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]'>Module 10</p>
      </div>

      <Timeline2 completedSteps={completedSteps} />

      <div className="flex flex-col gap-2">
        <p className='bg-card p-2 text-body text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]'>STEP 02: Winning Strategy</p>
        <p className='text-dark-green text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]'>
          In this section, the question to ask is ‘What must be true for the company to achieve its objectives? How will
          the company create superior value for consumers and customers to secure a competitive advantage and win in the
          marketplace?
        </p>
        <p className='text-dark-green my-4 font-bold text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]'>
          The below schedule provides the matrix of choices for achieving the objectives to deliver maximum value creation.
          Be choiceful and explain how you will win by making the choices.
        </p>

        <table className="border border-green-400 border-collapse text-dark-green mb-20 w-full">
          <thead className='text-center mb-4 
            text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]'>
            <tr>
              <th className="border border-green-400 px-4 py-2 w-[20%]">Objectives</th>
              <th className="border border-green-400 px-4 py-2" colSpan="5">
                Some Suggestions on how to Achieve it
              </th>
            </tr>
          </thead>
          <tbody className='text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]'>
            <tr>
              <td className="border border-green-400 px-4 py-2">Increasing Sales by Delighting the Customers
              </td>
              <td className="border border-green-400 px-4 py-2">Pricing, Promotion, Mix</td>
              <td className="border border-green-400 px-4 py-2">New Product initiatives</td>
              <td className="border border-green-400 px-4 py-2">New distribution channels</td>
              <td className="border border-green-400 px-4 py-2">Superior marketing campaign</td>
              <td className="border border-green-400 px-4 py-2">Better ways of meeting customer needs</td>
            </tr>
            <tr>
              <td className="border border-green-400 px-4 py-2">Profit Growth</td>
              <td className="border border-green-400 px-4 py-2">Pricing</td>
              <td className="border border-green-400 px-4 py-2">New profitable initiatives, New Needs</td>
              <td className="border border-green-400 px-4 py-2">Cost savings, Margin expansion</td>
              <td className="border border-green-400 px-4 py-2">Better product/ category mix</td>
              <td className="border border-green-400 px-4 py-2">Scale</td>
            </tr>
            <tr>
              <td className="border border-green-400 px-4 py-2">Cost / Productivity</td>
              <td className="border border-green-400 px-4 py-2">Better technology</td>
              <td className="border border-green-400 px-4 py-2">Effecient systems & processes</td>
              <td className="border border-green-400 px-4 py-2">Recognization</td>
              <td className="border border-green-400 px-4 py-2">Scale</td>
              <td className="border border-green-400 px-4 py-2">Cost saving</td>
            </tr>
            <tr>
              <td className="border border-green-400 px-4 py-2">Organization / Governance</td>
              <td className="border border-green-400 px-4 py-2">Simplification</td>
              <td className="border border-green-400 px-4 py-2">Deligation of Authority</td>
              <td className="border border-green-400 px-4 py-2">Strong processes</td>
              <td className="border border-green-400 px-4 py-2">Stewardship</td>
              <td className="border border-green-400 px-4 py-2">Employee evaluation systems</td>
            </tr>
            <tr>
              <td className="border border-green-400 px-4 py-2">Delighting Customers</td>
              <td className="border border-green-400 px-4 py-2">Superior products/services</td>
              <td className="border border-green-400 px-4 py-2">Excellent Value Equation (Quality / Price)</td>
              <td className="border border-green-400 px-4 py-2">After Sales Service</td>
              <td className="border border-green-400 px-4 py-2">Meeting customer needs better that Competition</td>
              <td className="border border-green-400 px-4 py-2">Ease of purchase</td>
            </tr>
            <tr>
              <td className="border border-green-400 px-4 py-2">Other</td>
              <td className="border border-green-400 px-4 py-2"></td>
              <td className="border border-green-400 px-4 py-2"></td>
              <td className="border border-green-400 px-4 py-2"></td>
              <td className="border border-green-400 px-4 py-2"></td>
              <td className="border border-green-400 px-4 py-2"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flex justify-end mt-4">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  )
}

export default Module_09_c
