import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../utility/axios';
import NavigationButtons from './shared/NavigationButtons';

const RadioButtonGroup = ({ name, options, selectedValue, onChange }) => (
  <div className="flex flex-col w-full max-w-screen  space-y-3 mb-3  
            text-[12px]     w-[87vw]
            sm:text-[14px]  sm:w-[80vw]
            md:text-[15px]  md:w-[80vw]
            lg:text-[17px]
            xl:text-[19px]
            2xl:text-[20px]">
    {options.map((option) => (
      <label key={option.value} className="w-full cursor-pointer">
        <input
          type="radio"
          name={name}
          value={option.value}
          className="hidden peer"
          checked={selectedValue === option.value}
          onChange={() => onChange(option.value)}
        />
        <div
          className={`w-full px-2 py-1 border-2 rounded-xl text-left transition-colors duration-300 ${selectedValue === option.value
              ? 'bg-teal-800 text-white border-teal-800'
              : 'text-teal-800 border-teal-800 hover:bg-teal-800 hover:text-lemon-green'
            }`}
        >
          {option.label}
        </div>
      </label>
    ))}
  </div>
);

const Module_1b_For_Startups = () => {
  const navigate = useNavigate();
  const { module_id, otp } = useParams();
  sessionStorage.setItem('module_id', module_id)
  sessionStorage.setItem('otp', otp)

  const [selectedOptions, setSelectedOptions] = useState(() => {
    // Load saved selections from sessionStorage on initial load
    const savedSelections = sessionStorage.getItem(`module_${module_id}_selections`);
    return savedSelections ? JSON.parse(savedSelections) : {};
  });

  const [questionData, setQuestionData] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(`module/startup?module_id=${module_id}&otp=${otp}`)
      .then((response) => {
        const data = response[0]?.data;
        if (data) {
          console.log('data:', data);
          
          setQuestionData(data.sort((a, b) => a.startup_id - b.startup_id));
          // setSelectedOptions((prevState) => ({
          //   ...prevState,
          //   [startup_id]: value,
          // }));
          // setSelectedOptions(data.map((item)=> [item.startup_id]: `option_${item.selected_option}`));
          setSelectedOptions(
            data.reduce((acc, item) => {
              acc[item.startup_id] = `option_${item.selected_option}`;
              return acc;
            }, {})
          );
        } else {
          console.error('Unexpected API response format');
        }
      })
      .catch((error) => {
        console.error('There was an error fetching the data!', error);
      });
  }, [module_id, otp]);

  // Clear sessionStorage on refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.removeItem(`module_${module_id}_selections`);
    };

    // Listen for page refresh
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [module_id]);

  const handlePrevious = () => {
    navigate('/Module_01_StartUps');
  };

  const handleNext = async () => {
    try {
      // Save selected options to sessionStorage
      sessionStorage.setItem(`module_${module_id}_selections`, JSON.stringify(selectedOptions));

      // Prepare data to send to the backend
      const formattedData = questionData.map((section) => ({
        startup_id: section.startup_id,
        value: selectedOptions[section.startup_id]?.replace('option_', ''),
      }));

      // Post the selected options to the backend
      await axiosInstance.post(`module/save_startup_value?otp=${otp}`, formattedData);

      // Navigate to the next module on successful post
      navigate('/Module_1B_Result_Startups');
    } catch (error) {
      console.error('Error saving selected options:', error.response ? error.response.data : error.message);
    }
  };

  const handleOptionChange = (startup_id, value) => {
    console.log('startup_id:',startup_id);
    
    setSelectedOptions((prevState) => ({
      ...prevState,
      [startup_id]: value,
    }));
  };

  useEffect(()=>{
    console.log('selectedOptions:',selectedOptions);
    
  }, [selectedOptions])

  return (
    <div className="w-[80%] flex flex-col items-start px-4 py-4 mb-16 sm:px-8 sm:py-4">
      {/* Additional Question 1 at the top */}
      {/* <div className="text-dark-green text-left text-base sm:text-lg p-4 rounded-lg mb-4 w-full max-w-screen text-[11px] w-[87vw]
            sm:text-[15px]  sm:w-[85vw]
            md:text-[17px]
            lg:text-[19px]
            xl:text-[22px]
            2xl:text-[25px]">
        <div className="mb-4">What is the projected growth rate of your target market over the next 5 years?</div>
        <RadioButtonGroup
          name="question_1"
          options={[
            { value: 'option_1', label: 'Greater than 20% annually' },
            { value: 'option_2', label: 'Between 10% and 20% annually' },
            { value: 'option_3', label: 'Less than 10% annually' },
          ]}
          selectedValue={selectedOptions['question_1']}
          onChange={(value) => handleOptionChange('question_1', value)}
        />
      </div> */}

      {/* Check if questionData is available before rendering */}
      {questionData ? (
        questionData.map((section, index) => (
          <div key={index} className="text-dark-green text-left text-base sm:text-lg p-4 rounded-lg mb-4 w-full max-w-screen text-[11px]
            sm:text-[15px]  sm:w-[85vw]
            md:text-[17px]
            lg:text-[19px]
            xl:text-[22px]
            2xl:text-[25px]">
            <div className="mb-4">{section.question}</div>
            <RadioButtonGroup
              name={`question_${index + 2}`}
              options={[
                { value: `option_1`, label: section.option_1 },
                { value: `option_2`, label: section.option_2 },
                { value: `option_3`, label: section.option_3 },
              ]}
              selectedValue={selectedOptions[section.startup_id]}
              onChange={(value) => handleOptionChange(section.startup_id, value)}
            />
          </div>
        ))
      ) : (
        <div>Loading...</div>
      )}

      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_1b_For_Startups;
