import React from 'react';
import RadioGroupTailed from './shared/RadioGroupTailed'; // Adjust the path according to your project structure

const Test = () => {
  const options = [
    { id: '1', name: 'Option 1', color: 'red' },
    { id: '2', name: 'Option 2', color: 'blue' },
    { id: '3', name: 'Option 3', color: 'green' },
  ];

  return (
    <div className="Test">
      <h1 className="text-center text-2xl mb-4">Choose an Option</h1>
      <RadioGroupTailed options={options} title="Select Your Preference" />
    </div>
  );
};

export default Test;
