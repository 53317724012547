import React, { useEffect, useState } from "react";
import ModuleTemplateCustom from "./shared/ModuleTemplateCustom";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";

const Module_8b = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [goals, setGoals] = useState([
    {
      id: 1,
      question: "One-Year Goals",
      suggestions: [
        "Profit growth target vs YA",
        "Sales/revenue growth vs YA",
        "CASH: % of conversion",
      ],
    },
    {
      id: 2,
      question: "Five-Year Goals",
      suggestions: [
        "Profit growth target vs YA",
        "Sales/revenue growth vs YA",
        "CASH: % of conversion",
      ],
    },
  ]);

  // State to track the text input for One-Year and Five-Year Goals
  const [oneYearGoal, setOneYearGoal] = useState({});
  const [fiveYearGoal, setFiveYearGoal] = useState({});

  // Handle text area changes for One-Year and Five-Year goals
  const handleGoalChange = (index, value, option) => {
    console.log('index, value, option', index, value, option);

    if (option === 1) {
      setOneYearGoal((prevState) => ({
        ...prevState,
        [index]: value,
      }));
    } else if (option === 2) {
      setFiveYearGoal((prevState) => ({
        ...prevState,
        [index]: value,
      }));
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`module/setting-the-direction-goals?otp=${savedOtp}&name=purpose`)
      .then((response) => {
        console.log("response:", response);

        // Initialize the form fields with the data from the API
        const { one_year_goals, five_year_goals } = response;

        // Setting the state with the values fetched from the API
        if (one_year_goals) {
          setOneYearGoal(one_year_goals);
        }
        if (five_year_goals) {
          setFiveYearGoal(five_year_goals);
        }
      })
      .catch((error) => console.log("error:", error));
  }, [savedOtp]);

  // Function to add a One-Year Goal block
const addOneYearGoal = () => {
  setOneYearGoal((prevState) => ({
    ...prevState,
    [Object.keys(prevState).length]: '', // Adding a new empty entry
  }));
};

const addFiveYearGoal = () => {
  setFiveYearGoal((prevState) => ({
    ...prevState,
    [Object.keys(prevState).length]: '', // Adding a new empty entry
  }));
};

  const handleNext = () => {
    const postData = {
      one_year_goals: oneYearGoal,
      five_year_goals: fiveYearGoal,
    };
    console.log('postData', postData)

    axiosInstance
      .post(`module/setting-the-direction-goals?otp=${savedOtp}`, postData)
      .then((response) => navigate("/Module_09"))
      .catch((error) => console.log("error:", error));
  };

  useEffect(() => {
    console.log("oneYearGoal:", oneYearGoal);
    console.log("fiveYearGoal:", fiveYearGoal);
  }, [oneYearGoal, fiveYearGoal]);

  return (
    <div>
      <ModuleTemplateCustom
        title="Module 08 - Goal Setting"
        bgColor="bg-dark-green"
        description="Setting the Direction"
        goals={goals}
        addOneYearGoal={addOneYearGoal}
        addFiveYearGoal={addFiveYearGoal}
        onPrevious={() => navigate("/Module_19")}
        onNext={handleNext}
        handleGoalChange={handleGoalChange}
        oneYearGoal={oneYearGoal}
        fiveYearGoal={fiveYearGoal}
      />
    </div>
  );
};

export default Module_8b;