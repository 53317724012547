import React, { useEffect, useState, useRef } from 'react';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utility/axios';
import Spinner from "./shared/Spinner";

const Module_12 = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem('otp');
  const [formattedData, setFormattedData] = useState([{ strategic_competencies_id: 1, strategic_competencies_value: '' }]);
  const [loading, setLoading] = useState(true); // Loading state for initialization
  const textareaRef = useRef(null); // Reference to the textarea

  // Event handlers for navigation buttons
  const handlePrevious = () => navigate('/Module_12_Start');

  const handleNext = async () => {
    try {
      setLoading(true); // Set loading to true before the request
      const response = await axiosInstance.post(`module/strategic-competency?otp=${savedOtp}`, {
        module_id: 12,
        strategic_competencies_name: formattedData[0].strategic_competencies_value // Send the competency value
      });

      console.log('Submission response:', response);
      navigate('/Module_13_Start');
    } catch (error) {
      console.error('Error submitting strategic competencies:', error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleInputChange = (event) => {
    const updatedValue = event.target.value; // Get the new input value

    // Create a new array with the updated object
    const updatedFormattedData = [{
      ...formattedData[0], // Keep all the other properties of the object the same
      strategic_competencies_value: updatedValue // Update the value
    }];

    // Set the updated array
    setFormattedData(updatedFormattedData);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust based on content
    }
  };

  // Fetch initial data for the form
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get(`module/strategic-competency?otp=${savedOtp}`);
        console.log("Initialization response:", response);

        // Set formattedData with the received data
        setFormattedData([{ 
          strategic_competencies_id: response.id, 
          strategic_competencies_value: response.strategic_competencies_name 
        }]);

        // Adjust height after setting data
        setTimeout(adjustTextareaHeight, 0);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false); // Reset loading state after fetching
      }
    };

    if (savedOtp) {
      fetchInitialData();
    }
  }, [savedOtp]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [formattedData]); // Adjust height whenever the data changes

  const renderTableHeader = (headers) => (
    <thead>
      <tr>
        {headers.map((header, index) => (
          <th key={index} className="h-[5vh] border-collapse border border-green-500 text-center text-dark-green mt-8 mb-4 text-[5px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[12px]
            xl:text-[14px]
            2xl:text-[20px]">
            {header === "Human Capital" ? (
              <>
                Human <br /> Capital
              </>
            ) : header === "Operational Efficiency" ? (
              <>
                Operational <br /> Efficiency
              </>
            ) : header === "Customer Service" ? (
              <>
                Customer <br /> Service
              </>
            ) : header === "Innovation & R&D" ? (
              <>
                Innovation & <br /> R&D
              </>
            ) : header === "Brand Reputation" ? (
              <>
                Brand <br /> Reputation
              </>
            ) : header === "Marketing and sales expertise" ? (
              <>
                Marketing and <br /> sales expertise
              </>
            ) : header === "Financial Acumen" ? (
              <>
                Financial <br /> Acumen
              </>
            ) : (
              header
            )}
          </th>
        ))}
      </tr>
    </thead>
  );

  return (
    <div className="max-w-full mx-auto p-4 mt-4 sm:p-8">
      {/* Show spinner while loading */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Header */}
          <div className="flex justify-between items-center">
            <h2 className="text-black font-extrabold mb-2 font-bold sm:mb-0 inline text-[13px]
                sm:text-[16px]
                md:text-[18px]
                lg:text-[20px]
                xl:text-[22px]
                2xl:text-[30px]">Identifying & Developing Competencies to Win</h2>
          </div>

          {/* Module Title */}
          <div className="bg-module p-2 mb-4">
            <h3 className="text-dark-green text-[12px] h-[20px]
                sm:text-[18px]
                md:text-[20px]
                md:h-[25px]
                lg:text-[22px]
                lg: mb-[3px]
                lg:h-[28px]
                xl:text-[20px]
                2xl:text-[28px]
                2xl:h-[40px]">Module 12</h3>
          </div>

          {/* Description */}
          <div className="text-dark-green mt-8 text-left font-bold mb-4 text-[11px]
                sm:text-[13px]
                md:text-[15px]
                lg:text-[17px]
                xl:text-[20px]
                2xl:text-[25px]">
            Examples of strategic competencies include:
          </div>

          {/* New Table After Blue Heading */}
          <div className="overflow-x-auto mb-8">
            <table className="min-w-full text-dark-green text-xl font-bold border border-green-500">
              {renderTableHeader([
                "Strategic Competency",
                "Human Capital", "Operational Efficiency", "Customer Service", "Innovation & R&D",
                "Technology", "Brand Reputation", "Marketing and sales expertise", "Financial Acumen"
              ])}
            </table>
          </div>

          {/* Description */}
          <div className="text-dark-green mt-8 text-left font-bold mb-4 text-[11px]
                sm:text-[13px]
                md:text-[15px]
                lg:text-[17px]
                xl:text-[20px]
                2xl:text-[25px]">
            What are the strategic competencies needed to deliver the winning strategies? Why are they important?
          </div>

          {/* New Table After Blue Heading */}
          <div className="overflow-x-auto mb-8">
  <table className="min-w-full text-dark-green text-md font-base border border-green-500">
    <tbody>
      <tr>
        <td className="border font-bold border-green-500 pl-16 text-dark-green text-left text-[9px]
          sm:text-[9px]
          md:text-[11px]
          lg:text-[13px]
          xl:text-[15px]
          2xl:text-[20px]"
          style={{ width: "20%" }}>
          Strategic Competency
        </td>
        <td className="border border-green-500" style={{ width: "80%" }}>
      <textarea
            value={formattedData[0].strategic_competencies_value} // Controlled input
            onChange={handleInputChange} // Update on change
            className="bg-transparent border-none w-full outline-none focus:outline-none focus:shadow-none overflow-hidden resize-none text-[10px]
      sm:text-[12px]
      md:text-[14px]
      lg:text-[16px]
      xl:text-[18px]
      2xl:text-[19px]"
        style={{
          boxShadow: "none", // Remove blue focus outline
          overflowWrap: "break-word", // Ensure long words break if needed
          whiteSpace: "normal", // Allow normal text wrapping
        }}
        ref={textareaRef} // Attach the ref to the textarea
        rows={1} // Initial height of one row
        onInput={adjustTextareaHeight} // Adjust height based on input
      />
    </td>
      </tr>
    </tbody>
  </table>
</div>

          <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
        </>
      )}
    </div>
  );
};

export default Module_12;
