import React from 'react';

const NavigationButtons = ({ onPrevious, onNext, nextButtonText }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 p-2 mx-10 bg-transparent text-[7px]
            sm:text-[9px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[23px]">
      <div className="flex justify-between w-full">
        <button
          onClick={onPrevious}
          className="no-print bg-lemon-green text-dark-green rounded-full px-4 py-2 font-base">
          Previous
        </button>
        <button
          onClick={onNext}
          className="no-print bg-lemon-green text-dark-green rounded-full px-4 py-2 font-base">
          {nextButtonText ? nextButtonText : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default NavigationButtons;
