import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import axiosInstance from '../utility/axios'; // Import the axios instance

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleLogin = async () => {
    const data = `grant_type=&username=${encodeURIComponent(
      email
    )}&password=${encodeURIComponent(password)}&scope=&client_id=&client_secret=`;

    try {
      const response = await axiosInstance.post('token', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Assuming successful login if response status is 200
      console.log('Login successful:', response.data);
      setIsPasswordIncorrect(false);

      // Redirect to the /Admin page
      navigate('/');
    } catch (error) {
      console.error('Error during login:', error.response || error.message);
      setIsPasswordIncorrect(true);
    }
  };

  return (
    <div className="flex h-screen bg-body p-[2%] overflow-hidden 
      md:h-[96vh] lg:h-[90vh] xl:h-[88vh] 2xl:h-[90vh]">
      {/* Left Section */}
      <div className="w-[33%] flex flex-col justify-center items-center bg-[#97b4f7] p-8 relative">
        <div className="flex flex-col items-start"> 
          {/* Flex container for Welcome and hand emoji */}
          <div className="flex items-center mb-4">
            <h1 className="text-white font-base text-[20px]  
              sm:text-[35px]
              md:text-[40px] md:-my-2 md:-ml-2
              lg:text-[50px] lg:mr-3
              xl:text-[60px]
              2xl:text-[80px]">
              Welcome
            </h1>
            <img
              src="/image.png"
              alt="Decorative"
              className="
              w-[25px] mr-3
              sm:w-[43px]
              md:w-[53px]   
              lg:w-[63px]
              xl:w-[73px]
              2xl:w-[73px]
              "   
            /> 
          </div>
          
          {/* Email Input */}
          <input
            type="email"
            placeholder="Email-ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full max-w-md px-[10px] py-[2px] mb-4 border rounded-full bg-transparent border-dark-green text-white placeholder-white focus:outline-none focus:ring-1 relative z-10 text-[9px]
              sm:text-[12px]
              sm:py-2
              sm:px-4
              md:text-[13px]
              md:py-2
              md:px-4
              lg:text-[17px]
              lg:py-2
              lg:px-4
              xl:text-[20px]
              xl:py-2
              xl:px-4
              2xl:text-[23px]
              2xl:h-[56px]
              2xl:py-2
              2xl:px-4"      
          />
          
          {/* Password Input */}
          <div className="w-full max-w-md relative">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`w-full px-[10px] py-[2px] mb-1 border rounded-full bg-transparent text-[9px]
                sm:text-[12px]
              sm:py-2
              sm:px-4
              md:text-[13px]
              md:py-2
              md:px-4
              lg:text-[17px]
              lg:py-2
              lg:px-4
              xl:text-[20px]
              xl:py-2
              xl:px-4
              2xl:text-[23px]
              2xl:h-[56px]
              2xl:py-2
              2xl:px-4"   
              ${
                isPasswordIncorrect ? 'border-[#B44452]' : 'border-dark-green'
              } text-white placeholder-white focus:outline-none focus:ring-1 relative z-10`}
            />
            {isPasswordIncorrect && (
              <p className="text-[#B44452] text-sm absolute -bottom-5 left-2">
                Incorrect password
              </p>
            )}
          </div>
          
          {/* Forgot Password */}
          <div className="text-right w-full max-w-md mb-6 relative z-10 text-[7px]
              sm:text-[8px]
              md:text-[10px]
              lg:text-[13px]
              xl:text-[15px]
              2xl:text-[17px]
              2xl:mt-[5px]">
            <a href="#" className="text-white">
              Forgot Password?
            </a>
          </div>

          {/* Sign In Button */}
          <button
            onClick={handleLogin}
            className="w-full max-w-md bg-green-800 text-white py-2 rounded-2xl mb-4 relative z-10 text-[9px]
              sm:text-[13px]
              md:text-[15px]
              lg:text-[17px]
              xl:text-[20px]
              2xl:text-[25px]"
          >
            SIGN IN
          </button>
        </div>
      </div>

      {/* Spacer Section */}
      <div className="w-[3%]"></div> {/* Spacer */}

      {/* Right Section */}
      <div className="w-[64%] relative flex flex-col justify-center items-center bg-lemon-green text-white">
        {/* Background image container */}
        <div className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url(/bg.png)', 
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.1, 
          }}>
        </div>

        {/* Content on top of the image */}
        <div className="relative z-10 text-end px-16">
          <p className="text-dark-green ml-32 font-light leading-tight text-[15px]
          sm:text-[20px]
          md:text-[25px]
          lg:text-[30px]
          lg:ml-[150px]
          xl:text-[50px]
          2xl:text-[64px]
          2xl:mt-[64px]
          2xl:ml-[200px]">
            Strategy is not the <br />
            consequence of planning. <br />
            but the opposite. It is the <br /> <span className="font-bold">starting point</span>
          </p>
          <p className="text-[#232222] mt-4 text-[10px]
          sm:text-[20px]
          md:text-[22px]
          lg:text-[22px]
          xl:text-[24px]
          2xl:text-[26px]">HENRY MINTZBERG</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
