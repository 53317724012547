import React, { createContext, useState } from "react";

// Create the context
export const MyContext = createContext();

// Create a provider component
export const MyContextProvider = ({ children }) => {
  const [sharedValue, setSharedValue] = useState(["", "", ""]);
  const [oneYearGoal, setOneYearGoal] = useState([])

  const handleChange = (index, value) => {

    setSharedValue((prevState) =>
      prevState.map((item, arrIndex) => (index == arrIndex ? value : item))
    );
  };

  const handleGoalChange = (index, value) => {
    setOneYearGoal(prevState=> {
      const newOneYearGoal = [...prevState]
      newOneYearGoal[index] = value
      return newOneYearGoal
    })
  }

  return (
    <MyContext.Provider value={{ sharedValue, setSharedValue, handleChange, handleGoalChange, oneYearGoal }}>
      {children}
    </MyContext.Provider>
  );
};
