import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_01_Start_SME = () => {
  const navigate = useNavigate();
  const moduleId = sessionStorage.getItem('module_id');
  const otp = sessionStorage.getItem('otp');
  const handleNext = () => navigate(`/Module_1A_SME/${moduleId}/${otp}`);
  const handlePrevious = () => navigate();

  return (
    <div className="mx-auto h-full flex items-center justify-center p-8 sm:p-4">
      <TransitionModule 
        title={
          <div className="tracking-[8px] text-[16px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]">
           ENTERPRISE ASSESSMENT
           </div>
        }
        description={
          <div className="block mt-4 text-center text-[14px] leading-relaxed
            text-[10px] 
            sm:text-[15px] 
            md:text-[14px] 
            lg:text-[18px] 
            xl:text-[20px] 
            2xl:text-[30px]">
The goal is to evaluate where your company stands on the value creation spectrum. We use six key questions to measure performance across critical metrics. The results will reveal whether your company is building or eroding value and identify the potential for improvement. This assessment is crucial for understanding your current position and guiding strategic decisions for growth.

</div>
          // </span>
        }
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
  );
};

export default Module_01_Start_SME;
