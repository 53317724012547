import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Strategize from './components/Strategize';
import LoginPage from './components/LoginPage';
import Admin from './components/Admin';
import Module_01_Start_SME from './components/Module_01_Start_SME';
import Module_1A_Result_SME from './components/Module_1A_Result_SME';
import Module_01_StartUps from './components/Module_01_StartUps';
import Module_1b_For_Startups from './components/Module_1b_For_Startups';
import Module_1B_Result_Startups from './components/Module_1B_Result_Startups';
import Module_02_Start from './components/Module_02_Start';
import Module_02 from './components/Module_02';
import Module_03_Start from './components/Module_03_Start';
import Module_3 from './components/Module_3';
import Module_3b from './components/Module_3b';
import Module_04_Start from './components/Module_04_Start';
// import Module_4 from './components/Module_4';
import Module_05_Start from './components/Module_05_Start';
import Module_5 from './components/Module_5';
import Module_06_Start from './components/Module_06_Start';
import Module_6 from './components/Module_6';
import Module_07_Start from './components/Module_07_Start';
import Module_07 from './components/Module_07';
import Module_07b from './components/Module_07b';
import Module_07c from './components/Module_07c';
import Module_07d from './components/Module_07d';
import Module_07e from './components/Module_07e';
import Module_08_Start from './components/Module_08_Start';
import Module_8 from './components/Module_8';
import Module_8b from './components/Module_8b';
import Test from './components/Test';
import Module_09 from './components/Module_09';
import Module_09_b from './components/Module_09_b';
import Module_09_c from './components/Module_09_c';
import Module_11_Start from './components/Module_11_Start';
import Module_11 from './components/Module_11';
import Module_12_Start from './components/Module_12_Start';
import Module_12 from './components/Module_12';
import Module_13_Start from './components/Module_13_Start';
import Module_13 from './components/Module_13';
import Module_14_Start from './components/Module_14_Start';
import Module_09_d from './components/Module_09_d';
import Module_09_e from './components/Module_09_e';
import Module_14 from './components/Module_14';
import Module_15_Start from './components/Module_15_Start';
import Module_15 from './components/Module_15';
import Module_16 from './components/Module_16';
import Module_17 from './components/Module_17';
import Module_19 from './components/Module_19';
import Module_22 from './components/Module_22';
import Module1A_SME from './components/Module1A_SME';
import Module_4 from './components/Module_4';
import { MyContextProvider } from './components/shared/hook/MyContext';

 
function App() {  
  return (
    <MyContextProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Admin />} />
            <Route path="/Module_01_Start_SME" element={<Module_01_Start_SME />} />
            <Route path="/Module_1A_SME/:module_id/:otp" element={<Module1A_SME />} />

            <Route path="/Module_1A_Result_SME" element={<Module_1A_Result_SME />} />
            <Route path="/Module_01_StartUps" element={<Module_01_StartUps />} />
            <Route path="/Module_1b_For_Startups/:module_id/:otp" element={<Module_1b_For_Startups />} />
            <Route path="/Module_1B_Result_Startups" element={<Module_1B_Result_Startups />} />
            <Route path="/Module_02_Start" element={<Module_02_Start />} />
            <Route path="/Module_02" element={<Module_02 />} />
            <Route path="/Module_03_Start" element={<Module_03_Start />} />
            <Route path="/Module_3" element={<Module_3 />} />
            <Route path="/Module_3b" element={<Module_3b />} />
            <Route path="/Module_04_Start" element={<Module_04_Start />} />
            {/* <Route path="/Module_4" element={<Module_4 />} /> */}
            <Route path="/Module_4" element={<Module_4 />} />

            <Route path="/Module_05_Start" element={<Module_05_Start />} />
            <Route path="/Module_5" element={<Module_5 />} />
            <Route path="/Module_06_Start" element={<Module_06_Start />} />
            <Route path="/Module_09" element={<Module_09 />} />
            <Route path="/Module_09_b" element={<Module_09_b />} />
            <Route path="/Module_09_c" element={<Module_09_c />} />
            <Route path="/Module_6" element={<Module_6 />} />
            <Route path="/Module_07_Start" element={<Module_07_Start />} />
            <Route path="/Module_07" element={<Module_07 />} />
            <Route path="/Module_07b" element={<Module_07b />} />
            <Route path="/Module_07c" element={<Module_07c />} />
            <Route path="/Module_07d" element={<Module_07d />} />
            <Route path="/Module_07e" element={<Module_07e />} />
            <Route path="/Module_08_Start" element={<Module_08_Start />} />
            <Route path="/Module_8b" element={<Module_8b />} />
            <Route path="/Module_11_Start" element={<Module_11_Start />} />
            <Route path="/Module_11" element={<Module_11 />} />
            <Route path="/Module_12_Start" element={<Module_12_Start />} />
            <Route path="/Module_12" element={<Module_12 />} />
            <Route path="/Module_13_Start" element={<Module_13_Start />} />
            <Route path="/Module_13" element={<Module_13 />} />
            <Route path="/Module_14_Start" element={<Module_14_Start />} />
            <Route path="/Module_09_d" element={<Module_09_d />} />
            <Route path="/Module_09_e" element={<Module_09_e />} />
            <Route path="/Module_14" element={<Module_14 />} />
            <Route path="/Module_15_Start" element={<Module_15_Start />} />
            <Route path="/Module_15" element={<Module_15 />} />
            <Route path="/Module_16" element={<Module_16 />} />
            <Route path="/Module_17" element={<Module_17 />} />
            <Route path="/Module_19" element={<Module_19 />} />
            <Route path="/Module_22" element={<Module_22 />} />
            <Route path="/test" element={<Test />} />
            <Route path="/Strategize" element={<Strategize />} />
            <Route path="/LoginPage" element={<LoginPage />} />
          </Routes>
        </div>
      </Router>
    </MyContextProvider>
  );
}
export default App;
