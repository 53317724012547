import React, { useEffect, useState } from 'react';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utility/axios';

// Reusable component for rating badge and description
const RatingBadge = ({ label, percentageRange, bgColor, textColor }) => (
  <div className='flex ml-10'>
    <span className={`font-bold w-10 h-10 flex items-center justify-center rounded-full ${bgColor} ${textColor} text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[70px]
            2xl:h-[70px]`}>
      {label}
    </span>
    <div className='mt-3 ml-4 text-dark-green text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]'>
      {percentageRange}
    </div>
  </div>
);

const Module_1B_Result_Startups = () => {
  const navigate = useNavigate(); // Hook to use navigation
  const [rating, setRating] = useState('');
  const [loading, setLoading] = useState(false);
  const moduleId = sessionStorage.getItem('module_id');
  const savedOtp = sessionStorage.getItem('otp');

  // Event handlers for navigation buttons
  const handlePrevious = () => navigate(`/Module_1b_For_Startups/${moduleId}/${savedOtp}`);
  const handleNext = () => navigate('/Module_02_Start');

  useEffect(() => {
    setLoading(true);
    // Fetch data from API
    axiosInstance.get('module/startup_score')
      .then(({ result }) => {
        // Assuming that the response is correct and sets the result as rating
        setRating(result ?? "N/A");
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      })
      .finally(() => setLoading(false));
  }, []);

  // Determine the badge color based on the rating
  const getBadgeClass = () => {
    switch (rating) {
      case "A":
        return "bg-lemon-green text-dark-green";  
      case "B":
        return "bg-module text-dark-green";  
      case "C":
        return "bg-rose-400 text-white";   
      default:
        return "bg-gray-400 text-white";  
    }
  };

  return (
    <div className='flex flex-col justify-between h-[100vh]'>
      {
        loading ? (
          <>Loading...</>
        ) : (
          <>
            <div className="flex justify-between border p-2 border-black rounded-full items-center m-10">
              <h2 className="font-semibold text-dark-green ml-3 text-[15px]
                sm:text-[20px]
                md:text-[22px]
                lg:text-[25px]
                xl:text-[30px]
                2xl:text-[40px]">Results</h2>
              <div className="flex items-center space-x-2">
                <span className="font-semibold text-dark-green mr-2 text-[15px]
                sm:text-[20px]
                md:text-[22px]
                lg:text-[25px]
                xl:text-[30px]
                2xl:text-[40px]">Rating</span>
                {/* Conditionally apply class based on rating */}
                <span className={`font-bold w-10 h-10 flex items-center justify-center rounded-full text-[14px]
                  sm:text-[18px]
                  md:text-[20px]
                  lg:text-[20px]
                  lg:w-[40px]
                  lg:h-[40px]
                  xl:text-[25px]
                  xl:w-[50px]
                  xl:h-[50px]
                  2xl:text-[33px]
                  2xl:w-[70px]
                  2xl:h-[70px] ${getBadgeClass()}`}>
                  {rating}
                </span>
              </div>
            </div>
          </>
        )
      }

      {/* Use the reusable RatingBadge component for each badge */}
      <div className='flex'>
        <RatingBadge 
          label="A" 
          percentageRange="Above 75%" 
          bgColor="bg-lemon-green" 
          textColor="text-dark-green" 
        />
        <RatingBadge 
          label="B" 
          percentageRange="60-74%" 
          bgColor="bg-module" 
          textColor="text-dark-green" 
        />
        <RatingBadge 
          label="C" 
          percentageRange="Below 74%" 
          bgColor="bg-rose-400" 
          textColor="text-white" 
        />
      </div>

      <div className="flex-1 flex justify-end items-end mx-8 mb-4">
        <div className='w-full'>
          <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
        </div>
      </div>
    </div>
  );
};

export default Module_1B_Result_Startups;
