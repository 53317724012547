import React, { useEffect, useState } from 'react';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utility/axios'; // Import the axios instance
import Spinner from "./shared/Spinner";

const Module_13 = () => {
    const navigate = useNavigate();
    const savedOtp = sessionStorage.getItem('otp');
    const [storyDescription, setStoryDescription] = useState('');
    const [loading, setLoading] = useState(true); // Loading state for initialization

    // Fetch initial data for the form
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const response = await axiosInstance.get(`module/story_or_narrative?otp=${savedOtp}`);
                console.log("Initialization response:", response);
                
                // Set story description with the received data
                setStoryDescription(response.story_description);
            } catch (error) {
                console.error('Error fetching initial data:', error);
            } finally {
                setLoading(false); // Reset loading state after fetching
            }
        };

        if (savedOtp) {
            fetchInitialData();
        }
    }, [savedOtp]);

    const handlePrevious = () => navigate('/Module_13_Start');

    const handleNext = async () => {
        try {
            setLoading(true); // Set loading to true before the request
            const response = await axiosInstance.post(`module/story_or_narrative?otp=${savedOtp}`, {
                module_id: 13, // Set the module_id
                story_description: storyDescription // Send the story description
            });

            console.log('Submission response:', response);
            navigate('/Module_14_Start'); // Navigate to the next module
        } catch (error) {
            console.error('Error submitting story description:', error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="max-w-full sm:max-w-9xl mx-6 mx-auto p-4 mt-4 sm:p-8">
            {loading ? (
                <div className="flex justify-center items-center h-full">
                    <Spinner /> {/* Show spinner while loading */}
                </div>
            ) : (
                <>
                    {/* Header */}
                    <div className="flex justify-between items-center">
                        <h2 className="text-black font-extrabold mb-2 font-bold inline text-[13px]
                            sm:text-[16px]
                            md:text-[18px]
                            lg:text-[20px]
                            xl:text-[22px]
                            2xl:text-[30px]">Story or Narrative</h2>
                    </div>

                    {/* Module Title */}
                    <div className="bg-cost p-2 mb-4">
                        <h3 className="text-body h-[30px] text-[18px] 
                            sm:text-[18px]
                            md:text-[20px]
                            lg:text-[22px]
                            lg: mb-[3px]
                            xl:text-[20px]
                            2xl:text-[28px]
                            2xl:h-[48px]">Module 13</h3>
                    </div>

                    <div className="text-dark-green mt-8 text-left mb-4 font-bold text-[11px]
                        sm:text-[13px]
                        md:text-[15px]
                        lg:text-[17px]
                        xl:text-[20px]
                        2xl:text-[25px]">
                        Develop a story or narrative for your brand that helps create an emotional connect with the consumers/customers.
                    </div>

                    <div className="relative rounded-2xl my-2">
                        <textarea
                            className="w-full text-dark-green rounded-2xl bg-transparent border border-dark-green
                                text-[10px]
                                sm:text-[14px]
                                sm:h-[95px]
                                md:text-[15px]
                                md:h-[120px]
                                lg:text-[17px]
                                lg:h-[130px]
                                xl:text-[18px]
                                xl:h-[115px]
                                2xl:text-[20px]
                                2xl:h-[155px]"
                            placeholder="Tips: Think what key contribution of your brand or service is. Exaggerate with some performance to back the claim. Be seen as a leader in the area where you claim to be contributing to. Be visible, be vocal."
                            value={storyDescription}
                            onChange={(e) => setStoryDescription(e.target.value)}
                        />
                    </div>

                    <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
                </>
            )}
        </div>
    );
};

export default Module_13;
