import React, { useState } from 'react';

const RadioGroupTailed = ({ options, title }) => {
  const [selectedName, setSelectedName] = useState(options[0]?.name || '');

  const getButtonColor = (name, color) =>
    selectedName === name ? `bg-${color} border-${color}` : 'bg-transparent border-dark-green';

  return (
    <div className="flex flex-col pt-6 pb-10">
      <h2 className="text-base-plus font-normal mb-4 text-dark-green">{title}</h2>
      <div className="relative w-full flex items-center">
        <div className={`mr-2 text-${options[0]?.color || 'dark-green'}`}>DECLINE</div>
        {options.map((option, index) => (
          <React.Fragment key={option.name}>
            <div className="relative flex flex-col items-center">
              <button
                onClick={() => setSelectedName(option.name)}
                className={`w-6 h-6 border rounded-full ${getButtonColor(option.name, option.color || 'dark-green')} relative z-10`}
              />
              <span className="absolute mt-2 top-6 text-sm text-center text-dark-green font-normal w-20">{option.name}</span>
            </div>
            {index < options.length - 1 ? (
              <div className={`flex-1 h-1 bg-${option.color || 'dark-green'} transform translate-y-1/5`}></div>
            ) : (
              <>
                <div className={`w-8 h-1 bg-${option.color || 'dark-green'} transform translate-y-1/5 rounded-full`}></div>
                <div className={`ml-2 text-${option.color || 'dark-green'}`}>GAIN</div>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default RadioGroupTailed;
