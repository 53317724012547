import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_03_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_02');
  const handleNext = () => navigate('/Module_3');

  return (
    <TransitionModule
      title={<div className="tracking-[8px] text-[11px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]">
        IDENTIFYING COMPETITIVE ADVANTAGE
      </div>}
      description={<div className="block mt-4 text-center text-[12px] leading-relaxed
              text-[10px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]
            ">
        We know that competitive advantage can be on one of the two vectors: <br />1. <strong> Differentiation </strong> <br />2. <strong>Cost</strong>
      </div>}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_03_Start;
