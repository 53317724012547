import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../utility/axios'; // Import the axios instance
import { FaChevronDown } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';

const Admin = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    module: '',
  });

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropdownSelect = (module) => {
    setFormData({
      ...formData,
      module,
    });
    setIsDropdownOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct the payload from formData
    const payload = {
      username: formData.username,
      email: formData.email,
      module: formData.module,
    };
    console.log(payload);

    try {
      toast.promise(
        axiosInstance.post('module/generate-otp', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0YWRtaW4iLCJpYXQiOjE3MjQ0MzAwNDAsImF1dGhfbW9kZSI6IkxPQ0FMIiwiZXhwIjoyMDM1NDcwMDQwfQ.1FHKV0noZN398ybiF730ahWUG9gnFhLA-W7fEPi-GNs',
          },
        }),
        {
          loading: 'Generating OTP...',
          success: <b>OTP Sent!</b>,
          error: <b>Could not send OTP.</b>,
        }
      );
      localStorage.setItem('module', formData.module);
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='flex justify-center items-center h-screen gap-6'>
      <div className='w-full max-w-sm 2xl:max-w-lg'>
        <h1 className='font-extrabold text-dark-green mb-8 text-center text-[35px] 
            sm:text-[40px]   sm:mb-1
            md:text-[45px]   md:mb-2  
            lg:text-[55px]   lg:mb-3
            xl:text-[65px]   xl:mb-4
            2xl:text-[75px]'>Admin</h1>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="username"
            placeholder='Username'
            value={formData.username}
            onChange={handleInputChange}
            className='px-4 py-2 bg-transparent outline outline-dark-green rounded-lg w-full mb-6 placeholder-dark-green text-dark-green 
            text-[19px]
            sm:text-[19px]
            md:text-[20px]      
            lg:text-[22px]  lg:py-3  
            xl:text-[24px]  xl:py-2  
            2xl:text-[30px] 2xl:py-3'
            style={{ lineHeight: '1.5rem' }}
          />

          <div className='relative mb-6' ref={dropdownRef}>
            <button
              type="button"
              onClick={toggleDropdown}
              className='px-4 py-2 bg-transparent border border-dark-green text-dark-green rounded-lg w-full text-left flex justify-between items-center outline outline-dark-green 
            text-[19px]
            sm:text-[19px]
            md:text-[20px]     
            lg:text-[22px]  lg:py-3 
            xl:text-[24px]  xl:py-2     
            2xl:text-[30px] 2xl:py-3'
              style={{ lineHeight: '1.5rem' }}
            >
              {formData.module || 'Select Module'}
              <FaChevronDown className={`transform ${isDropdownOpen ? 'rotate-180' : ''} transition-transform`} />
            </button>

            {isDropdownOpen && (
              <div className='absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg'>
                <button
                  type="button"
                  onClick={() => handleDropdownSelect('sme')}
                  className='block px-4 py-2 text-gray-700 rounded-md hover:bg-dark-green hover:text-white w-full text-left
            text-[19px] 
            sm:text-[19px]
            md:text-[20px]     
            lg:text-[22px]  lg:py-3
            xl:text-[24px]  xl:py-2       
            2xl:text-[30px]'
                >
                  sme
                </button>
                <button
                  type="button"
                  onClick={() => handleDropdownSelect('startup')}
                  className='block px-4 py-2 text-gray-700 rounded-md hover:bg-dark-green hover:text-white w-full text-left 
            text-[19px]
            sm:text-[19px]
            md:text-[20px]  
            lg:text-[22px]  lg:py-3   
            xl:text-[24px]  xl:py-2     
            2xl:text-[30px]'
                >
                  startup
                </button>
              </div>
            )}    
          </div>
          <input
            type="email"
            name="email"
            placeholder='Email'
            value={formData.email}
            onChange={handleInputChange}
            className='text-dark-green px-4 py-2 bg-transparent outline outline-dark-green rounded-lg w-full mb-8 placeholder-dark-green 
            text-[19px] 
            sm:text-[19px]
            md:text-[20px]  
            lg:text-[22px]  lg:py-3 
            xl:text-[24px]  xl:py-2     
            2xl:text-[30px] 2xl:py-3'
            style={{ lineHeight: '1.5rem' }}
          />
          <div className="flex justify-center">
            <button type="submit" className='bg-lemon-green text-dark-green py-2 px-16 rounded-full 
            text-[19px]     px-[130px]
            sm:text-[19px]  sm:px-[130px]
            md:text-[20px]  md:py-2   md:px-[130px]    
            lg:text-[22px]  lg:py-2   lg:px-[120px] 
            xl:text-[24px]  xl:py-1   xl:px-[100px]   
            2xl:text-[30px] 2xl:px-40 2xl:py-2 2xl:mt-2 whitespace-nowrap'>
              Generate OTP
            </button>
          </div>
        </form>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </div>
    </div>
  );
};

export default Admin;
