import React, {useEffect, useState} from 'react';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import axiosInstance from "../utility/axios";

const Module_19 = () => {
    const navigate = useNavigate();
    const savedOtp = sessionStorage.getItem("otp");
    const [objective, setObjective] = useState('')

  useEffect(() => {
    axiosInstance
      .get(`module/setting-the-direction?otp=${savedOtp}&name=objective`)
      .then((response) => {
        console.log("response:", response);
        setObjective(response.value);
      })
      .catch((error) => console.log("error:", error));
  }, [savedOtp]);
    const handlePrevious = () => navigate('/Module_17');
    const handleNext = () => {
        axiosInstance
      .post(`module/setting-the-direction?otp=${savedOtp}`, {
        module_name: "objective",
        value: objective,
      })
      .then((response) => console.log("response:", response))
      .catch((error) => console.log("error:", error));
        navigate('/Module_8b')
    }

  return (
    <div className="p-8 bg-body mx-3 min-h-screen">
      <h1
        className="text-light-green font-bold  sm:mb-0 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]"
      >
        Setting the Direction
      </h1>
      <div className="bg-dark-green p-1 mb-3 text-white">
        <h2
          className="text-body h-[30px] text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[48px]"
        >
          Module 08
        </h2>
      </div>
      <div>
        <div>
          <p
            className="text-dark-green text-dark-green mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            <strong>OBJECTIVE:</strong> Objectives enable the company to bring
            its aspirations or vision into a reality by moving towards it
            year-on-year. As you develop the objectives you may consider that
            objectives generally are in the following areas:
          </p>
        </div>
      </div>

      {/* Objective Table Section */}
      <div className="my-8">
        <table
          className="table-fixed w-full border-collapse border border-green-500 text-dark-green mt-8  mb-4 text-[5px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[12px]
            xl:text-[14px]
            2xl:text-[15px]"
        >
          <thead>
            <tr>
              <th className="border border-green-500 py-2" colSpan={"2"}></th>
              <th className="border border-green-500 p-2">Market Share</th>
              <th className="border border-green-500 p-2">Profit</th>
              <th className="border border-green-500 p-2">Productivity</th>
              <th className="border border-green-500 p-2">Innovation</th>
              <th className="border border-green-500 p-2">
                Organization/ <br />
                Society
              </th>
              <th className="border border-green-500 p-2">Others</th>
            </tr>
          </thead>
          <tbody>
  <tr className='text-[9px]
      sm:text-[9px]
      md:text-[11px]
      lg:text-[13px]
      xl:text-[14px]
      2xl:text-[20px]' >
    <td
      className="border border-green-500 p-3 text-dark-green text-left mb-4 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[12px]
            xl:text-[16px]
            2xl:text-[20px]"
      colSpan="2"
    >
      What are your company objectives to meet the vision? One year
      and 3 years.
    </td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
    <td className="border border-green-500 p-2 text-[8px]
            sm:text-[8px]
            md:text-[10px]
            lg:text-[14px]
            xl:text-[15px]
            2xl:text-[20px]" contentEditable="true" style={{ verticalAlign: 'top' }}></td>
  </tr>
</tbody>

        </table>
        <p
          className="text-dark-green font-semibold mt-8 mb-3 text-left mb- text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[19px]
            2xl:text-[25px]"
        >
          Draft Objective
        </p>
        <div>
          <div className="relative">
            <textarea
              className="w-full p-3 text-dark-green rounded-2xl bg-transparent border border-dark-green
                           text-[10px]
                           sm:text-[12px]
                           sm:h-[95px]
                           md:text-[14px]
                           md:h-[120px]
                           lg:text-[16px]
                           lg:h-[130px]
                           xl:text-[18px]
                           xl:h-[115px]
                           2xl:text-[19px]
                           2xl:h-[155px]"
                           onChange={(e) => setObjective(e.target.value)}
                           value={objective}
                        />
                        <div className="absolute top-[-3.0rem] right-[145px] mt-2 group">
                            <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
                                <div className="absolute font-semibold text-[10px] top-[1.10rem] -right-[140px]
            sm:text-[12px]
            sm:-right-[135px]
            sm:-top-[-.80rem]
            md:-right-[135px]
            md:text-[14px]
            md:-top-[-.40rem]
            lg:text-[16px] 
            lg:-right-[135px]
            xl:text-[18px]
            2xl:text-[20px]"
                >
                  Suggestions
                </div>
              </div>
              <div className="hidden group-hover:block text-dark-green absolute top-0 right-0 w-[300px] bg-white border p-2 rounded-lg shadow-lg text-xs sm:text-sm">
                <p className="mb-4">
                  Clearly write the key objectives for your business that will
                  help you to move closer to achieving the overall vision of the
                  company.
                </p>
                <p className="mb-4">
                  One way to do this is to ask the question,{" "}
                  <strong>‘what has to be true to deliver the vision.’</strong>
                </p>
                <p>
                  Yearly objectives can then be the mileposts leading to the
                  vision.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_19;
