import React, { useEffect, useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import Timeline3 from "./shared/Timeline3";
import axiosInstance from "../utility/axios"; // Import the axios instance

const Module_09_e = () => {
  const navigate = useNavigate();
  const completedSteps = [
    "PLAYING FIELD",
    "WINNING STRATEGY",
    "WHAT NOT TO DO",
  ];
  const savedOtp = sessionStorage.getItem("otp");

  // State to hold form data
  const [formData, setFormData] = useState([
    { strategicChoice: "", reason: "", valueLost: "" },
    { strategicChoice: "", reason: "", valueLost: "" },
    { strategicChoice: "", reason: "", valueLost: "" },
  ]);

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await axiosInstance.get(`module/choices-of-winning-strategy-what-not-to-do?otp=${savedOtp}`);
        console.log("Initialization response:", response);

        // Update form data with the fetched values
        setFormData([
          { strategicChoice: response.strategic_choice[0] || "", reason: response.reason[0] || "", valueLost: response.value_lost[0] || "" },
          { strategicChoice: response.strategic_choice[1] || "", reason: response.reason[1] || "", valueLost: response.value_lost[1] || "" },
          { strategicChoice: response.strategic_choice[2] || "", reason: response.reason[2] || "", valueLost: response.value_lost[2] || "" },
        ]);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [savedOtp]);

  // Handle form input changes
  const handleInputChange = (e, index, field) => {
    const updatedFormData = [...formData];
    updatedFormData[index][field] = e.target.value;
    setFormData(updatedFormData);
  };

  // Handle form submission
  const onNext = async () => {
    // Reshape form data to match the expected structure
    const data = {
      strategic_choice: formData.map((item) => item.strategicChoice),
      reason: formData.map((item) => item.reason),
      value_lost: formData.map((item) => item.valueLost),
    };

    try {
      // Send POST request with the reshaped form data
      await axiosInstance.post(`/module/choices-of-winning-strategy-what-not-to-do?otp=${savedOtp}`, {
        ...data,
      });
      console.log("Data posted successfully:", data);
      navigate("/Module_11_Start");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const onPrevious = () => navigate("/Module_09_d");

  return (
    <div className="p-12">
      <p className="font-bold inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]">
        Choices of Winning Strategy
      </p>
      <div className="mb-8">
        <p className="bg-light-purple p-2 text-body text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]">
          Module 10
        </p>
      </div>

      <Timeline3 completedSteps={completedSteps} />

      <div className="mb-8">
        <p className="bg-light-green p-2 text-body text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]">
          STEP 03: WHAT NOT TO DO
        </p>
      </div>

      {/* Editable Table */}
      <form>
        <table className="border border-green-500 border-collapse text-dark-green text-left w-full text-[7px]
            sm:text-[9px]
            md:text-[11px]
            lg:text-[13px]
            xl:text-[15px]
            2xl:text-[20px]">
          <thead>
    <tr>
      <th className="border border-green-500 px-4 py-3 w-[40%]">
        Strategic Choice - WHAT WE WOULD NOT DO
      </th>
      <th className="border border-green-500 px-4 py-3">
        Why the choice was made
      </th>
      <th className="border border-green-500 px-4 py-3">
        Potential Value Lost
      </th>
    </tr>
  </thead>
  <tbody>
    {formData.map((row, index) => (
      <tr key={index}>
        <td className="border border-green-500 px-3 py-2 align-top">
          <textarea
            value={row.strategicChoice}
            onChange={(e) => handleInputChange(e, index, "strategicChoice")}
            className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
            sm:text-[12px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
            2xl:text-[20px]"
            rows={1}
            style={{
              resize: "none", // Disable manual resizing
              overflow: "hidden", // Hide scrollbars
            }}
            onInput={(e) => {
              e.target.style.height = "auto"; // Reset height
              e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height based on scrollHeight
            }}
          />
        </td>
        <td className="border border-green-500 px-3 py-2 align-top">
          <textarea
            value={row.reason}
            onChange={(e) => handleInputChange(e, index, "reason")}
            className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
            sm:text-[12px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
            2xl:text-[20px]"
            rows={1}
            style={{
              resize: "none",
              overflow: "hidden",
            }}
            onInput={(e) => {
              e.target.style.height = "auto";
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
          />
        </td>
        <td className="border border-green-500 px-3 py-2 align-top">
          <textarea
            value={row.valueLost}
            onChange={(e) => handleInputChange(e, index, "valueLost")}
            className="w-full bg-transparent p-0 text-dark-green border-none focus:outline-none focus:ring-0 focus:border-none text-[10px]
            sm:text-[12px]
            md:text-[14px]
            lg:text-[16px]
            xl:text-[18px]
            2xl:text-[20px]"
            rows={1}
            style={{
              resize: "none",
              overflow: "hidden",
            }}
            onInput={(e) => {
              e.target.style.height = "auto";
              e.target.style.height = `${e.target.scrollHeight}px`;
            }}
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

      </form>

      {/* Navigation Buttons */}
      <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
    </div>
  );
};

export default Module_09_e;
