import React from 'react';
import NavigationButtons from '../shared/NavigationButtons';
import ReactHtmlParser from 'react-html-parser';

const TransitionModule = ({ title, description, onPrevious, onNext }) => {
  const isString = (description) => typeof description === 'string';

  return (
    <div className="flex flex-col min-h-screen  bg-body px-4 sm:px-6 lg:px-8">
      {/* Flex container for alignment */}
      <div className="flex-grow flex mb-20 items-center justify-center">
        {/* Content Card with responsive width */}
        <div className={`max-w-[90%]
            max-w-[600px]
            sm:max-w-[1100px]
            md:max-w-[600px]
            lg:max-w-[800px]
            xl:max-w-[1000px]
            2xl:max-w-[1385px]
            bg-card text-body rounded-3xl shadow-lg 
            p-4 
            sm:p-6 
            md:p-7
            lg:p-9
            xl:p-8
            2xl:p-16
`}>
          
          {/* Responsive Title */}
          <h2 className={`text-center text-[26px] font-semibold tracking-wider
            text-[9px]
            sm:text-[20px]
            md:text-[20px]
            lg:text-[28px]
            xl:text-[26px]
            2xl:text-[35px]
            `}>
          {title}
          </h2>
          
          {/* Divider */}
          <div className="border-t border-lemon-green my-3 w-full"></div>
          
          {/* Responsive Description */}
          <p className={`text-center text-[22px] leading-relaxed
            text-[10px]
            sm:text-[18px] 
            md:text-[18px] 
            lg:text-[20px] 
            xl:text-[24px]  
            2xl:text-[30px]
            `}>
          {isString(description) ? ReactHtmlParser(description) : description}
          </p>
        </div>
      </div>
      
      {/* Navigation buttons at the bottom */}
      <div className="flex justify-between mt-4 px-4 sm:px-6 lg:px-8">
        <NavigationButtons onPrevious={onPrevious} onNext={onNext} />
      </div>
    </div>
  );
};

export default TransitionModule;
