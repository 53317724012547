import React, { useState, useEffect } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";

const Module_17 = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [vision, setVision] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`module/setting-the-direction?otp=${savedOtp}&name=vision`)
      .then((response) => {
        console.log("response:", response);
        setVision(response.value);
      })
      .catch((error) => console.log("error:", error));
  }, [savedOtp]);
  const handlePrevious = () => navigate("/Module_16");
  const handleNext = () => {
    axiosInstance
      .post(`module/setting-the-direction?otp=${savedOtp}`, {
        module_name: "vision",
        value: vision,
      })
      .then((response) => console.log("response:", response))
      .catch((error) => console.log("error:", error));
    navigate("/Module_19");
  };

  return (
    <div className="p-8 bg-body mx-3 min-h-screen">
      <h1
        className="text-light-green font-bold  sm:mb-0 inline text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]"
      >
        Setting the Direction
      </h1>
      <div className="bg-dark-green p-1 mb-3 text-white">
        <h2
          className="text-body h-[30px] text-[18px] 
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            lg: mb-[3px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[48px]"
        >
          Module 08
        </h2>
      </div>
      <div>
        <div>
          <p
            className="text-dark-green mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            <strong>VISION:</strong>Vision setting is all about the power of
            imagination. Imagine what your company would be in 5-10 years if it
            is successful. A vision statement provides a brief description of a
            company’s long-term goals. It is typically ambitious and
            communicates how the company plans to make a difference in the
            world.
          </p>
          <p
            className="text-dark-green font-semibold mt-16 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            Draft Vision Statement
          </p>
          <div className="relative">
            <textarea
              className="w-full p-3 text-dark-green rounded-2xl bg-transparent border border-dark-green
    text-[10px]
    sm:text-[12px]
    sm:h-[95px]
    md:text-[14px]
    md:h-[120px]
    lg:text-[16px]
    lg:h-[130px]
    xl:text-[18px]
    xl:h-[115px]
    2xl:text-[19px]
    2xl:h-[155px]"
              placeholder="Tips: The vision statement should include what you want your company to become and how it will contribute to the world."
              onChange={(e) => setVision(e.target.value)}
              value={vision}
            />

            <div className="absolute top-[-3.30rem] right-[145px] mt-2 group">
              <div className="relative text-xs sm:text-sm text-teal-700 cursor-pointer">
                <div
                  className="absolute font-semibold -right-[135px] -top-[-.50rem] text-[10px] mt-1
            sm:text-[13px]
            sm:-right-[125px]
            sm:-top-[-.50rem]
            md:-right-[135px]
            md:text-[15px]
            md:-top-[-.40rem]
            lg:text-[16px] 
            lg:-right-[135px]
            xl:text-[18px]
            2xl:text-[20px]"
                >
                  Suggestions
                </div>
              </div>
              <div
                className="hidden group-hover:block absolute top-0 right-0 w-[300px] bg-white border p-2 rounded-lg shadow-lg text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[13px]
            2xl:text-[15px]
            2xl:w-[318px]"
              >
                <h1 className="text-card font-semibold">Tesla</h1>
                <p>
                  To create the most compelling car company of the 21st century
                  by driving the world’s transition to electric vehicles.
                </p>
                <hr className="my-2 border-t-2 border-lemon-green" />
                <h1 className="text-card font-semibold">Amazon</h1>
                <p>
                  To be Earth’s most customer-centric company; to build a place
                  where people can come to find and discover anything they might
                  want to buy online.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_17;
