import React, { useState, useEffect } from 'react';
import axiosInstance from '../utility/axios';
import NavigationButtons from './shared/NavigationButtons';
import { useNavigate } from 'react-router-dom';
const Module_1A_Result_SME = () => {
  // Define state variables
  const [consumerValue, setConsumerValue] = useState(0);
  const [customerValue, setCustomerValue] = useState(0);
  const [companyShareholderValue, setCompanyShareholderValue] = useState(0);
  const [rating, setRating] = useState('');
  const [total, setTotal] = useState(0);

  const navigate = useNavigate(); // Hook to use navigation
  const moduleId = sessionStorage.getItem('module_id')
  const otp = sessionStorage.getItem('otp')
  console.log(moduleId);
  console.log("hellllllo",otp);
  
  // Event handlers for navigation buttons
  const handlePrevious = () => navigate(`/Module_1A_SME/${moduleId}/${otp}`);
  const handleNext = () => navigate('/Module_02_Start');

  useEffect(() => {
    axiosInstance.get(`module/sme_score?otp=${otp}`).then(response => {
      const data = response;
      setConsumerValue(data.consumer);
      setCustomerValue(data.customer);
      setCompanyShareholderValue(data.company_share_holder);
      setRating(data.result);
      setTotal(data.total);
    }).catch(error => {
      console.error('Error fetching data:', error);
    });
  }, []);
  
  return (
    <div className="mt-6 mx-8 text-white p-4 rounded-md">
      <div className="flex justify-between border pl-4 p-1 border-black rounded-full items-center mb-10">
        <h2 className="font-semibold text-dark-green text-[15px]
            sm:text-[20px]
            md:text-[22px]
            lg:text-[25px]
            xl:text-[30px]
            2xl:text-[40px]">Results</h2>
        <div className="flex items-center space-x-4">
          <span className="font-semibold text-dark-green text-[15px]
            sm:text-[20px]
            md:text-[22px]
            lg:text-[25px]
            xl:text-[30px]
            2xl:text-[40px]">Rating</span>
          <span className={`font-bold flex items-center justify-center rounded-full text-[14px] p-2
  sm:text-[18px]
  md:text-[20px]
  lg:text-[20px]
  ${rating === "Excellent" ? "bg-lemon-green text-dark-green" : 
    rating === "Good" ? "bg-module text-dark-green" : 
    rating === "Needs improvement" ? "bg-pink text-body" : 
    rating === "Not creating value" ? "bg-danger text-body" : ""}`}>
  
  {/* Letter inside a white circle */}
  <span className="bg-body text-dark-green flex items-center justify-center w-[30px] h-[30px] rounded-full mr-2">
    {rating === "Excellent" ? "A" : 
     rating === "Good" ? "B" : 
     rating === "Needs improvement" ? "C" : 
     rating === "Not creating value" ? "D" : ""}
  </span>
  
  {/* Rating Text */}
  {rating}
</span>


        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <p className="text-light-green font-semibold mt-6 text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]  2xl:mt-[28px]
            ">CONSUMER VALUE</p>
          </div>
          <div className='flex flex-col items-end justify-center gap-2 mb-3'>
            <span className="ml-2 bg-module text-dark-green text-lg font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">{consumerValue}</span>
            <p className="text-dark-green text-right text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">Consumer Value Creation is <span className='font-bold'>{
             consumerValue >= 8 ? 'EXCELLENT' : 
             consumerValue >= 6 ? 'VERY GOOD' :
             consumerValue >= 3 ? 'GOOD':
             consumerValue >= 0 ? 'NEEDS IMPROVEMENT' : 
             'NEEDS IMMEDIATE ATTENTION'
              } 
              </span></p>
          </div>
        </div>
        <div className="flex justify-between items-center border-t border-gray-500 py-2 2xl:py-[22px]">
          <div>
            <p className="text-light-green font-semibold  text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]  2xl:mt-[28px]
            ">CUSTOMER VALUE</p>
          </div>
          <div className='flex flex-col items-end justify-center gap-2'>
            <span className="ml-2 bg-module text-dark-green text-lg font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">{customerValue}</span>
            <p className="text-dark-green text-right text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">Customer Value Creation <span className='font-bold'>{
             customerValue >= 8 ? 'EXCELLENT' : 
             customerValue >= 6 ? 'VERY GOOD' :
             customerValue >= 3 ? 'GOOD':
             customerValue >= 0 ? 'NEEDS IMPROVEMENT' : 
             'NEEDS IMMEDIATE ATTENTION'
              } 
              </span></p>
          </div>
        </div>
        <div className="flex justify-between items-center border-t border-gray-500 py-2 2xl:py-[22px]">
          <div>
            <p className="text-light-green font-semibold  text-[13px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]  2xl:mt-[28px]
            ">COMPANY/SHAREHOLDER VALUE</p>
          </div>
          <div className='flex flex-col items-end justify-center gap-2'>
            <span className="ml-2 bg-module text-dark-green text-lg font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">{companyShareholderValue}</span>
            <p className="text-dark-green text-right text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">Company/Shareholder Value Creation <span className={`font-bold ${companyShareholderValue >= 3 ? 'text-green-600' : 'text-red-600'}`}>
              {companyShareholderValue >= 8 ? 'EXCELLENT' :
               companyShareholderValue >= 6 ? 'VERY GOOD' :
               companyShareholderValue >= 3 ? 'GOOD' :
               companyShareholderValue >= 0 ? 'NEEDS IMPROVEMENT' :
               'NEEDS IMMEDIATE ATTENTION'}
            </span></p>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center border-t border-gray-500 py-2"></div>
      <div className="mt-4 bg-teal-500 text-body font-semibold py-2 px-4 rounded-full text-start text-[15px]
            sm:text-[20px]
            md:text-[22px]
            lg:text-[25px]
            xl:text-[30px]
            2xl:text-[40px]">
        Total: {total}
      </div>
      <div className="mt-8 grid grid-cols-2 gap-4"> 
  <div className="flex items-center"> 
    <span className="ml-2 bg-lemon-green text-dark-green font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">A</span>
    <div className="ml-4 text-dark-green text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">
      Scores between 18-24 means <br /><strong>excellent value creation.</strong>
    </div>
  </div>
  <div className="flex items-center justify-end"> 
    <span className="ml-2 bg-cost text-body font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">C</span>
    <div className="ml-4 text-dark-green text-left text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">
      Scores between 6 and 12 means the company is <strong>creating <br />value but have significant room for improvement.</strong> 
    </div>
  </div>
  <hr className="col-span-2 border-dark-green" />
  
  <div className="flex items-center"> 
    <span className="ml-2 bg-module text-dark-green font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">B</span>
    <div className="ml-4 text-dark-green text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">
    Scores between 12-18 means<br /><strong>good value creation.</strong>
    </div>
  </div>
  <div className="flex items-center justify-end  lg:mr-[120px] xl:mr-[136px] 2xl:mr-[170px]"> 
    <span className="ml-2 bg-danger text-body font-bold w-8 h-8 flex items-center justify-center rounded-full text-[14px]
            sm:text-[18px]
            md:text-[20px]
            md:w-[30px]
            md:h-[30px]
            lg:text-[20px]
            lg:w-[40px]
            lg:h-[40px]
            xl:text-[25px]
            xl:w-[50px]
            xl:h-[50px]
            2xl:text-[33px]
            2xl:w-[65px]
            2xl:h-[65px]">D</span>
    <div className="ml-4 text-dark-green text-left text-[8px]
            sm:text-[10px]
            md:text-[12px]
            lg:text-[14px]
            xl:text-[16px]
            2xl:text-[20px]">
    For scores below 6. <strong>immediate acting <br /> is required.</strong> 
    </div>
  </div>
</div>
      <div className="flex justify-end mt-32">
        <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
      </div>
    </div>
  );
};
export default Module_1A_Result_SME;
