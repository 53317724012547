import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Table = ({ data = [], headers = [] }) => {
  // Type guard to check if a value is a string
  const isString = (value) => typeof value === 'string';

  // Generate header statements array from headers prop
  const headerStatements = headers.map(header => header.statement || '');

  // If data is not provided or has fewer than 4 rows, fill with empty rows
  const filledData = Array.isArray(data) && data.length >= 4
    ? data
    : Array.isArray(data)
    ? [...data, ...Array.from({ length: 4 - data.length }, () => Array(headerStatements.length).fill(''))]
    : Array.from({ length: 4 }, () => Array(headerStatements.length).fill(''));

  return (
    <div className="">
      <table className="min-w-full bg-transparent border-none table-fixed">
        {headerStatements.length > 0 && (
          <thead>
            <tr className="flex w-full">
              {headerStatements.map((cell, cellIndex) => (
                <td key={cellIndex} className="flex-1 py-2 border border-b-2 border-light-green text-md-plus font-bold text-center">
                  {isString(cell) ? ReactHtmlParser(cell) : cell}
                </td>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {filledData.map((row, rowIndex) => (
            <tr key={rowIndex} className="flex w-full">
              {Array.isArray(row) ? row.map((cell, cellIndex) => (
                <td key={cellIndex} className={`flex-1 py-2 px-4 border border-light-green text-base-plus text-dark-green ${rowIndex === filledData.length - 1 && "border-b-2"}`}>
                  {isString(cell) ? ReactHtmlParser(cell) : cell}
                </td>
              )) : (
                <td colSpan={headerStatements.length} className="flex-1 py-2 px-4 border border-light-green text-base-plus text-dark-green">
                  Invalid row data
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
