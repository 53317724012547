import React, { useEffect, useState } from "react";
import NavigationButtons from "./shared/NavigationButtons";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utility/axios";

const Module_16 = () => {
  const navigate = useNavigate();
  const savedOtp = sessionStorage.getItem("otp");
  const [purpose, setPrurpose] = useState("");

  useEffect(() => {
    axiosInstance
      .get(`module/setting-the-direction?otp=${savedOtp}&name=purpose`)
      .then((response) => {
        console.log("response:", response);
        setPrurpose(response.value);
      })
      .catch((error) => console.log("error:", error));
  }, [savedOtp]);
  const handlePrevious = () => navigate("/Module_08_Start");
  const handleNext = () => {
    axiosInstance
      .post(`module/setting-the-direction?otp=${savedOtp}`, {
        module_name: "purpose",
        value: purpose,
      })
      .then((response) => console.log("response:", response))
      .catch((error) => console.log("error:", error));
    navigate("/Module_17");
  };
  return (
    <div className="p-8 bg-body mx-3 min-h-screen">
      <h1
        className="text-light-green font-bold  sm:mb-0 inline text-[14px]
            sm:text-[16px]
            md:text-[18px]
            lg:text-[20px]
            xl:text-[22px]
            2xl:text-[30px]"
      >
        Setting the Direction
      </h1>
      <div className="bg-dark-green p-1 mb-3 text-white">
        <h2
          className="text-body h-[28px] my-1 text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[48px]"
        >
          Module 08
        </h2>
      </div>
      <div>
        <div className="mb-8">
          <p
            className="text-dark-green text-dark-green mt-8 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            <strong>PURPOSE:</strong> A Purpose statement is a single statement
            that defines the reason your company exists—beyond simply making a
            profit. It also illustrates how your product or service positively
            impacts the people you serve. Once your purpose is established,
            you’ll need a series of goals to drive that purpose. Purpose is the{" "}
            <strong>"Why"</strong> your business exists. Qualities of a good
            purpose statement:
          </p>
          <ul
            className="list-decimal list-inside text-dark-green ml-2 my-16 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            <li>It differentiates the company from competition.</li>
            <li>
              A strong purpose statement sets a path for how your company will
              move forward, which will help you see and set clear goals.
            </li>
          </ul>
          <p
            className="text-dark-green font-semibold mt-16 text-left mb-4 text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[20px]
            2xl:text-[25px]"
          >
            Statement of Purpose
          </p>
          <div className="relative">
            <textarea
              className="w-full text-dark-green rounded-2xl bg-transparent border border-dark-green bg-transparent 
            max-w-[600px]
            sm:max-w-[600px]
            md:max-w-[700px]
            lg:max-w-[950px]
            xl:max-w-[1340px]
            2xl:max-w-[2620px]
            p-2 
            sm:p-2 
            md:p-2
            lg:p-3
            xl:p-3
            2xl:p-4
            text-[10px]
            sm:text-[12px] 
            md:text-[14px] 
            lg:text-[16px] 
            xl:text-[18px]  
            xl:h-[115px]
            2xl:text-[19px]
            2xl:h-[155px]"
              placeholder="Tips: The purpose should include the why it exists and how it improves the lives of its customers."
              onChange={(e) => setPrurpose(e.target.value)}
              value={purpose}
            />
            <div className="absolute top-[-3.0rem] right-[145px] mt-2 group">
              <div className="relative   text-teal-700 cursor-pointer">
                <span
                  className="absolute font-semibold right text-[11px] top-[7px] -right-[134px]
            sm:text-[13px]     sm:ml-[30px]   sm:top-[8px]
            md:text-[15px]     md:ml-[40px]
            lg:text-[17px]     lg:ml-[30px]
            xl:text-[18px]     
            2xl:text-[20px]
            2xl:ml-[4px]"
                >
                  Suggestions
                </span>
              </div>
              <div
                className="hidden group-hover:block absolute top-0 right-0 w-[270px] bg-white border p-2 rounded-lg shadow-lg text-[11px]
            sm:text-[13px]
            md:text-[15px]
            lg:text-[17px]
            xl:text-[13px]
            2xl:text-[15px]
            2xl:w-[318px]"
              >
                <h1 className="text-card font-semibold">AT&T</h1>
                <p>
                  Inspire human progress through the power of communication and
                  entertainment.
                </p>
                <hr className="my-2 border-t-2 border-lemon-green" />
                <h1 className="text-card font-semibold">Coca-Cola</h1>
                <p>Refresh the world. Make a difference.</p>
                <hr className="my-2 border-t-2 border-lemon-green" />
                <h1 className="text-card font-semibold">Pfizer</h1>
                <p>Working together for a healthier world.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default Module_16;
