import React, { useState, useEffect } from 'react';

const Timeline3 = ({ completedSteps }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [steps, setSteps] = useState([
    {
      number: '01',
      content: "PLAYING FIELD",
      isComplete: false,
    },
    {
      number: '02',
      content: "WINNING STRATEGY",
      isComplete: false,
    },
    {
      number: '03',
      content: "WHAT NOT TO DO",
      isComplete: false,
    }
  ]);

  const handleStepClick = (stepNumber) => {
    setCurrentStep(stepNumber);
  };

  // Update the isComplete status based on the completedSteps prop
  useEffect(() => {
    const updatedSteps = steps.map(step => ({
      ...step,
      isComplete: completedSteps.includes(step.content)
    }));
    setSteps(updatedSteps);
    console.log(completedSteps)
  }, [completedSteps]);

  return (
    <div className="flex flex-col w-1/2">
      <div className="flex items-center mb-16 w-full">
        {steps.map((step, index) => (
          <React.Fragment key={step.number}>
            <div
              className={`relative w-14 h-14 flex items-center justify-center rounded-full border cursor-pointer 
                h-[30px]
                w-[30px]
                sm:h-[30px]
                sm:w-[30px]
                md:h-[30px]
                md:w-[30px]
                lg:h-[40px]
                lg:w-[40px]
                xl:h-[50px]
                xl:w-[50px]                
                ${
                step.isComplete ? 'border-light-green bg-light-green text-white' : 'text-lg-plus font-bold border-4 bg-transparent border-light-purple text-light-blue'
              }`}
              onClick={() => handleStepClick(step.number)}
            >
              {step.isComplete ? <img src='/icons/tick.svg' alt="Completed" 
              className='w-6 h-6'/> : step.number}
              <span className={`pl-20 text-black text-center absolute top-16 text-nowrap text-[6px] mr-[72px]
            sm:text-[10px]
            sm:mr-[56px]
            md:text-[12px]
            md:mr-[38px]
            lg:text-[14px]
            lg:mr-[18px]
            xl:text-[16px]
            xl:mr-[18px]
            2xl:text-[18px]${index==0 && "ml-12"}`}>{step.content}</span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`h-1 flex-1 ${
                  steps[index + 1].isComplete && steps[index + 1].number < currentStep ? 'bg-light-green' : 'bg-light-green'
                }`}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Timeline3;
