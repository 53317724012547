import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Strategize = () => {
  const navigate = useNavigate();
  const onNext = () => navigate('/LoginPage');

  return (
    <div className="flex h-[95vh] bg-body p-[2%] 
    lg:h-[93vh] 
    xl:h-[88vh] 
    2xl:h-[91vh]
    "> {/* Specific padding on all sides */}
      <div className="w-[65%] relative flex flex-col justify-center items-start bg-[#8F93FFF2] text-white">
        {/* Background image container */}
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url(/bg.png)', // Set the background image path
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            opacity: 0.1, // Adjust the opacity for subtle visibility
          }}
        ></div>

        {/* Content on top of the image */}
        <div className="relative z-10">
          <div className="text-left pl-[20px] sm:pl-[30px] md:pl-[40px] lg:pl-[50px] xl:pl-[60px] 2xl:pl-[70px]">
            <h1 className="
              text-[40px]      mr-[40px] 
              sm:text-[60px]   sm:mr-[100px] 
              md:text-[70px]   md:mr-[70px] 
              lg:text-[80px]  lg:mr-[140px] 
              xl:text-[100px]  xl:mr-[300px] 
              2xl:text-[153px] 2xl:mr-[180px] 2xl:text-[153px] 
            ">
              Strategize
            </h1>
            <p className="text-white text-left mt-2 
            text-[12px] 
            sm:text-[17px] 
            md:text-[19px] 
            lg:text-[22px] 
            xl:text-[24px] 
            2xl:text-[33px]">
              Strategize your way upwards
            </p>
          </div>
          <button
            onClick={onNext}
            className="bg-[#193133] text-body mt-4 px-8 py-2 
            text-[12px]    ml-[20px]
            sm:text-[17px] sm:ml-[30px]
            md:text-[19px] md:ml-[40px]
            lg:text-[21px] lg:ml-[50px]
            xl:text-[22px] xl:mt-[20px]   xl:ml-[60px]
            2xl:mt-[35px] 2xl:text-[30px] 2xl:ml-[70px]"
          >
            SIGN IN
          </button>
        </div>
      </div>
      {/* Spacer Section */}
      <div className="w-[2%]"></div> {/* This div acts as the spacer */}

      {/* Right Section */}
      <div className="w-[35%] flex flex-col justify-center items-center bg-lemon-green p-8 relative">
        <h1 className="text-dark-green 
          text-[17px] mx-[8px] mt-[160px] leading-tight 
          sm:text-[22px] sm:mx-[8px] sm:mt-[160px] sm:leading-tight 
          md:text-[25px] md:mx-[8px] md:mt-[210px] md:leading-tight 
          lg:text-[30px] lg:mx-[8px] lg:mt-[200px] lg:leading-tight 
          xl:text-[40px] xl:mx-[12px] xl:mt-[250px] xl:leading-tight 
          2xl:text-[53px] 2xl:mx-[15px] 2xl:mt-[350px] 2xl:leading-tight">
          Are you ready to <br />
          strategize your way <br />
          upwards? Start your <br />
          journey with us
        </h1>
      </div>
    </div>
  );
};

export default Strategize;
