import React, { useState, useEffect } from "react";
import axiosInstance from "../utility/axios"; // Import the axios instance
import { useNavigate } from "react-router-dom";
import NavigationButtons from "./shared/NavigationButtons";
import Table from "./shared/Table";
import SliderSection from "./shared/SliderSection";
import ElaborateInput from "./shared/ElaborateInput";
import Spinner from "./shared/Spinner";
import toast, { Toaster } from "react-hot-toast";

const Module_3 = () => {
  const savedOtp = sessionStorage.getItem("otp");
  const [loading, setLoading] = useState(true);
  const [updateData, setUpdateData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [data, setData] = useState([]);
  const [updateItemId, setUpdateItemId] = useState([]);
  const [postItemId, setPostItemId] = useState([]);
  const [heading, setHeading] = useState("");

  useEffect(() => {
    axiosInstance
      .get(
        `module/current-situation-values?otp=${savedOtp}&module_id=${3}&heading=Differentiation`
      )
      .then((response) => {
        console.log("response:", response);
        const filteredResponse = response
          .filter((item) => item.heading == "Differentiation")
          .sort((a, b) => a.id - b.id);
        setHeading(filteredResponse[0].heading);
        setData(filteredResponse);
        setPostData(
          filteredResponse.map((item) => ({
            situation_id: item.id,
            selected_value: item.selected_value,
            descriptions: item.descriptions,
          }))
        );
        setLoading(false);
      })
      .catch((error) => {
        console.error("error:", error);
        setLoading(false);
      });
  }, [savedOtp]);

  useEffect(() => {
    console.log("data:", data);
    // console.log('updateData:',updateData);
    // console.log('updateItemId:',updateItemId);
    // console.log('postItemId:',postItemId);
  }, [data, updateData, updateItemId, postItemId]);

  const navigate = useNavigate(); // Hook to use navigation

  const handleSliderChange = (item, index, value) => {
    // console.log('item:', item);

    setData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], selected_value: value };
      return newApiData;
    });

    setPostData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], selected_value: value };
      return newApiData;
    });
  };

  const handleInputChange = (item, index, text) => {
    // console.log('item:', item);

    setData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], descriptions: text };
      return newApiData;
    });

    setPostData((prevData) => {
      const newApiData = [...prevData];
      newApiData[index] = { ...newApiData[index], descriptions: text };
      return newApiData;
    });
  };

  useEffect(() => {
    // Filter data based on updateItemId array and update updateData state
    const updatedItems = data
      .filter((item) => updateItemId.includes(item.id)) // Check if item.id is in updateItemId
      .map((item) => ({
        situation_id: item.id,
        selected_value: item.selected_value,
        descriptions: item.descriptions,
      }));

    setUpdateData(updatedItems);
  }, [updateItemId, data]);

  const handlePrevious = () => navigate("/Module_03_Start");

  const handleNext = () => {
    try {
      if (data.every((item) => item.selected_value !== null)) {
        console.log("it does not have null values");

        axiosInstance
          .post(`module/save_situation_value?otp=${savedOtp}`, postData)
          .then((response) => {
            console.log("POST response:", response);
          })
          .catch((error) => {
            console.error("error:", error);
          });

        navigate("/Module_3b");
      } else {
        console.log("it has null values");
        toast.error("Please select an option for all questions.");
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    console.log("postData:", postData);
  }, [postData]);

  return (
    <div className="mx-auto p-4 sm:p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-2">
        <h2
          className="text-dark-green font-extrabold sm:mb-0 inline text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[30px]">
          Current Situation
        </h2>
        <h2
          className="text-dark-green font-extrabold inline text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[30px]">
          Goal (1-3 years)
        </h2>
      </div>

      {/* Differentiation Title */}
      <div className="bg-bar p-2 mb-4">
        <h3
          className="text-black text-[9px]
            sm:text-[18px]
            md:text-[20px]
            lg:text-[22px]
            xl:text-[20px]
            2xl:text-[28px]
            2xl:h-[50px]">
          {heading}
        </h3>
      </div>

      {loading ? (
        <Spinner/>
      ) : (
        /* Sliders Section */
        <div className="mb-20">
          <Table
            data={data.map((item, index) => [
              <SliderSection
                key={`slider-${index}`}
                label={item.sub_heading}
                onChange={(value) => handleSliderChange(item, index, value)}
                selectedValue={data[index] && data[index].selected_value}
              />,
              <ElaborateInput
                key={`input-${index}`}
                onChange={(text) => handleInputChange(item, index, text)}
                value={data[index] && data[index].descriptions}
              />,
            ])}
          />
        </div>
      )}

      {/* Navigation Buttons */}
      <NavigationButtons onPrevious={handlePrevious} onNext={handleNext} />
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Module_3;
