import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_12_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_11');
  const handleNext = () => navigate('/Module_12');

  return (
    <TransitionModule
      title={<span className="tracking-[8px] text-[12px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]">IDENTIFYING & DEVELOPING <br /> COMPETENCIES TO WIN</span>}
      description={<div className=" \block mt-4 text-center text-[14px] leading-relaxed
              text-[10px]
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]">In this section, we identify and develop strategic competencies to win.</div>}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_12_Start;
