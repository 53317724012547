import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_07_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_6');
  const handleNext = () => navigate('/Module_07');

  // Description content
  const descriptionContent = [
    'Players (competitors)',
    'Ease of Entry',
    'Ease of Substitution',
    'Power of Suppliers',
    'Power of Buyers'
  ];

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-[600px]
            sm:max-w-[1100px]
            md:max-w-[600px]
            lg:max-w-[800px]
            xl:max-w-[1000px]
            2xl:max-w-[1385px]
            p-4 
            sm:p-6 
            md:p-7
            lg:p-9
            xl:p-8
            2xl:p-16">
    <TransitionModule
    title={
      <div className="racking-[8px] text-[13px] 
              sm:text-[15px] 
              md:text-[16px] 
              lg:text-[20px] 
              xl:text-[20px] 
              2xl:text-[35px]
      ">
       COMPETITIVE LANDSCAPE
        </div>}
    description={
      <div>
      <p className='my-2 
       text-[12px]
       sm:text-[16px] 
       md:text-[15px] 
       lg:text-[20px] 
       xl:text-[18px]  
       2xl:text-[30px]'>
            The purpose of the section is to understand the competitive landscape
            in which your enterprise is operating. To fully appreciate the landscape
            the section will look at the following strategic aspects:
          </p>
          <ul className="list-disc list-inside pl-0 mt-6  text-[12px]
            sm:text-[16px] 
            md:text-[15px] 
            lg:text-[20px] 
            xl:text-[18px]  
            2xl:text-[30px]">
            {descriptionContent.map((item, index) => (
              <li key={index} className="mb-2">{item}</li>
            ))}
          </ul>
        </div>
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
    </div>
    </div>
  );
};

export default Module_07_Start;
