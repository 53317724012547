import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_13_Start = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_12');
  const handleNext = () => navigate('/Module_13');

  return (
    <TransitionModule
      title={<div className="tracking-[8px] text-[15px] 
              sm:text-[15px] 
              md:text-[18px] 
              lg:text-[20px] 
              xl:text-[25px] 
              2xl:text-[35px]">STORY OR NARRATIVE</div>}
      description={<div className="block mt-4 text-center text-[13px] leading-relaxed
              text-[10px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[30px]">Carefully woven and emotionally touching stories can overcome competitive disadvantages to win as a strong and compelling narrative is a competitive advantage. We know that the equation for value is <strong>Value = (Quality + Performance) / Cost</strong> . However, the storytelling has the power to add more to the value to enable the brands to charge a higher price.</div>}
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_13_Start;
