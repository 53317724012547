import React from 'react';
import { useNavigate } from 'react-router-dom';
import TransitionModule from './shared/TransitionModule';

const Module_01_StartUps = () => {
  const navigate = useNavigate();
  const handlePrevious = () => navigate('/Module_1A_Result_SME');
  const handleNext = () => navigate('/Module_1b_For_Startups/2/975830');

  // Description content
  const descriptionContent = [
    '1. Market Growth',
    '2. Product/Market Fit',
    '3. Value Creation',
    '4. Business Model',
    '5. Competitive Advantage',
    '6. Funding',
    '7. Scalability'
  ];

  return (
    <TransitionModule
      title={
      <div className="racking-[8px] text-[9px] 
              sm:text-[15px] 
              md:text-[14px] 
              lg:text-[18px] 
              xl:text-[20px] 
              2xl:text-[35px]
      ">
        START-UP ASSESSMENT 
        </div>}
      description={
        <div>
           <p className='my-2 
            text-[10px]
            sm:text-[18px] 
            md:text-[15px] 
            lg:text-[20px] 
            xl:text-[18px]  
            2xl:text-[30px]'>
              The purpose of the assessment is to evaluate the startup idea on seven parameters:</p>
          {descriptionContent.map((item, index) => (
            <p className='my-2 
            text-[10px]
            sm:text-[18px] 
            md:text-[15px] 
            lg:text-[20px] 
            xl:text-[18px]  
            2xl:text-[30px]'
             key={index}>{item}</p>
          ))}
        </div>
      }
      onPrevious={handlePrevious}
      onNext={handleNext}
    />
  );
};

export default Module_01_StartUps;